<!-- <button style="width: auto;" type="button" class="btn login-with-google-btn" (click)="authService.GoogleAuth()">
    Continue with Google
  </button> -->
<div (keyup.enter)="authService.SignIn(userName.value, userPassword.value)" class="displayTable"
  style="padding-top: 0px;">
  <div class="displayTableCell">
    <div class="authBlock">
      <h3>{{ 'signIn' | translate }}</h3>
      <div class="formGroup">
        <input type="text" class="formControl" placeholder="Username" #userName required>
      </div>
      <div class="formGroup">
        <input type="password" class="formControl" placeholder="Password" #userPassword required>
      </div>
      <!-- Calling SignIn Api from AuthService -->
      <div class="formGroup">
        <input type="button" class="btn btnPrimary" value="Log in"
          (click)="authService.SignIn(userName.value, userPassword.value)">
      </div>
      <div class="formGroup">
        <span class="or"><span class="orInner"> {{ 'or' | translate }}</span></span>
      </div>
      <!-- Calling GoogleAuth Api from AuthService -->
      <div class="formGroup">
        <button type="button" class="btn bg-light google-login-btn" (click)="authService.GoogleAuth()">
          {{ 'continueWithGoogle' | translate }}
        </button>
      </div>
      <div class="formGroup">
        <span class="or"><span class="orInner"> {{ 'or' | translate }}</span></span>
      </div>
      <!-- <div class="formGroup">
        <button type="button" class="btn bg-info" (click)="authService.GuestSignIn()">
          <i class="fa fa-user-secret"></i>
          {{ 'guestLogin' | translate }}
        </button>
      </div> -->
      <div class="forgotPassword">
        <span routerLink="/forgot-password">{{ 'forgotPassword' | translate }}</span>
      </div>
    </div>
    <div class="redirectToLogin">
      <span>{{ 'dontHaveAccount' | translate }}<span class="redirect" routerLink="/sign-up">
          {{ 'signup' | translate }}</span></span>
    </div>
  </div>
</div>
<br>
<br>