import {
    Component,
    OnInit,
    ChangeDetectorRef,
} from '@angular/core';
import { UploadService } from '../services/upload.service';
import { loadScript } from '@paypal/paypal-js';
import { HttpEventType } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css'],
})

export class ProfileComponent implements OnInit {
    firestoreUser;
    paypal;
    paypalScreen = false;
    orderInfo = "";
    constructor(
        private uploadService: UploadService,
        private changeDetectorRef: ChangeDetectorRef,
        public router: Router
    ) {
    }
    ngAfterViewInit(): void {

    }
    back() {
        this.paypalScreen = false;
        this.changeDetectorRef.detectChanges();
    }
    async payment(count, price) {
        const tokensText = count > 1 ? "tokens" : "token";
        this.orderInfo = `Buy ${count} ${tokensText} for $${price}`;
        this.paypalScreen = true;
        this.changeDetectorRef.detectChanges();
        document.querySelector('.paypal-test').innerHTML = "";
        console.log(count, price)
        try {
            this.paypal = await loadScript({ "client-id": "AXhT0Pq7KAXZ8MsNgYsQgXV8831yw93Nv6K4RR7WsLs2GMYnrtRMRM9ZvackRbtaUvIOBs2BtCVO001D", "merchant-id": ["NMQUFLE72XEDU"] });
        } catch (error) {
            console.error("failed to load the PayPal JS SDK script", error);
        }

        if (this.paypal) {
            try {
                const that = this;
                await this.paypal.Buttons(
                    {
                        createOrder: function (data, actions) {
                            return actions.order.create({
                                purchase_units: [{
                                    amount: {
                                        value: price
                                    }
                                }],
                                application_context: {
                                    shipping_preference: 'NO_SHIPPING'
                                }
                            });
                        },
                        onApprove: function (data, actions) {
                            return actions.order.capture().then(function (details) {
                                this.paypalScreen = false;
                                const tokens = that.firestoreUser.tokens + count;
                                this.uploadService.updateUserData({
                                    ...that.firestoreUser,
                                    tokens
                                }).subscribe(async e => {
                                    if (e.type === HttpEventType.Response) {
                                        console.log(e);
                                    }
                                    console.log(e);
                                });
                                console.log(data);
                            });

                        }
                    }
                ).render(".paypal-test");
            } catch (error) {
                console.error("failed to render the PayPal Buttons", error);
            }
        }
    }

    async ngOnInit(): Promise<void> {
        document.getElementsByTagName('nav')[0].style.backgroundColor = '#3f51b5';
        const email = JSON.parse(localStorage.getItem("user")).email
        if (email) {
            this.uploadService.getUserData(email).subscribe((e) => {
                const firestoreUserData = e.data;
                this.firestoreUser = firestoreUserData;
                console.log(this.firestoreUser);
            });
        }
    }
}
