import {
    Component,
    OnInit,
    ChangeDetectorRef,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { UploadService } from '../services/upload.service';
import { animate } from '@angular/animations';

@Component({
    selector: 'app-gallery',
    templateUrl: './animate.component.html',
    styleUrls: ['./animate.component.css'],
})

export class AnimateComponent implements OnInit {
    width;
    height;
    basePhotoUrl;
    data;
    smallW;
    smallH;
    coef;
    id;
    baseImageData;
    fps = 6;
    alpha = 0.15;
    fpsInterval;
    startTime;
    now;
    then;
    elapsed;
    @ViewChild('canvas')
    myCanvas: ElementRef<HTMLCanvasElement>;
    public context: CanvasRenderingContext2D;

    @ViewChild('canvas2')
    myCanvas2: ElementRef<HTMLCanvasElement>;
    public context2: CanvasRenderingContext2D;
    constructor(
        private uploadService: UploadService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    ngAfterViewInit(): void {

    }

    async ngOnInit(): Promise<void> {
        const params = new URLSearchParams(window.location.search)
        this.id = params.get("id");
        this.coef = params.get("coef") ?? 1;
        this.fps = +(params.get("fps") || 6);
        this.alpha = +(params.get("alpha") || 0.15);
        document.getElementsByTagName('nav')[0].style.backgroundColor = '#3f51b5';
        if (this.id) {
            this.loadData();
        }
    }

    animate(array, index) {
        setTimeout(() => {
            if (index < array.length) {
                requestAnimationFrame(() => {
                    this.animate(array, index + 1);
                })
            }
            else {
                this.context2.clearRect(0, 0, this.width, this.height);
                this.context2.filter = 'blur(5px)';
                this.context2.globalAlpha = this.alpha;
                this.context2.drawImage(this.baseImageData, 0, 0, this.width, this.height);
                return;
            }
            this.now = Date.now();
            this.elapsed = this.now - this.then;

            // if enough time has elapsed, draw the next frame

            // if (this.elapsed > this.fpsInterval) {
            this.then = this.now - (this.elapsed % this.fpsInterval);
            // Get ready for next frame by setting then=now, but also adjust for your
            // specified fpsInterval not being a multiple of RAF's interval (16.7ms)


            const el = array[index] as any;
            this.context2.clearRect(0, 0, this.width, this.height);
            el.coords.map(c => {
                this.context.drawImage(el.data, c.x * this.coef, c.y * this.coef, this.smallW, this.smallH);
                this.context2.lineWidth = 1;
                this.context2.strokeStyle = "black";
                this.context2.strokeRect(c.x * this.coef, c.y * this.coef, this.smallW, this.smallH)
                if (c.rgb) {
                    this.context2.globalAlpha = 0.2;
                    this.context2.fillStyle = "rgb(" + c.rgb.r + "," + c.rgb.g + "," + c.rgb.r + ")";
                    this.context2.globalCompositeOperation = 'hue';
                    this.context2.fillRect(Math.floor(c.x * this.coef),
                        Math.floor(c.y * this.coef),
                        Math.floor(c * this.coef),
                        Math.floor(c * this.coef))
                    this.context2.globalAlpha = 1;
                    this.context2.fillStyle = "transparent";
                    this.context2.globalCompositeOperation = "source-over";
                }
            })
            this.context2.drawImage(el.data, 0, 0, this.width / 5, this.width / 5);
        }, 1000 / this.fps)

    }
    shuffle(array) {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex != 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }
    loadData() {
        this.uploadService.getMozlike(this.id).subscribe(e => {
            this.data = e.data.array;
            this.basePhotoUrl = e.data.info.bPhotoUrl;
            this.smallW = e.data.info.oldW * this.coef;
            this.smallH = e.data.info.oldH * this.coef;
            this.width = e.data.info.cols * this.smallW;
            this.height = e.data.info.rows * this.smallH;

            this.context = this.myCanvas.nativeElement.getContext('2d');

            this.myCanvas.nativeElement.width = this.width;
            this.myCanvas.nativeElement.height = this.height;

            this.context2 = this.myCanvas2.nativeElement.getContext('2d');

            this.myCanvas2.nativeElement.width = this.width;
            this.myCanvas2.nativeElement.height = this.height;

            const baseImage = new Image();
            let drawnCount = 0;
            baseImage.onload = () => {
                this.baseImageData = baseImage;
                this.context2.drawImage(baseImage, 0, 0, this.width, this.height);
                const imgsMap = {};
                let i = 0;
                while (i < this.data.length) {
                    const el = this.data[i];
                    const url = el.url;
                    if (!imgsMap[url]) {
                        imgsMap[url] = {
                            coords: [],
                            data: {}
                        };
                        const img = new Image();
                        img.onload = () => {
                            imgsMap[url].data = img;
                            const otherCoords = this.data.filter(e => e.url === url);
                            drawnCount += otherCoords.length;
                            otherCoords.map(c => {
                                imgsMap[url].coords.push({
                                    x: c.x,
                                    y: c.y
                                })
                            })

                            i++;
                            if (drawnCount === this.data.length) {
                                this.fpsInterval = 1000 / this.fps;
                                this.then = Date.now();
                                this.startTime = this.then;
                                const array = this.shuffle(Object.values(imgsMap));
                                requestAnimationFrame(() => {
                                    this.animate(array, 0);
                                })
                            }
                        }
                        img.src = url.replace(
                            'w=' + this.smallW / this.coef,
                            'w=300'
                        )
                            .replace(
                                'h=' + this.smallH / this.coef,
                                'h=300'
                            );
                    }
                    else {
                        i++;
                    }
                }
            }
            baseImage.src = this.basePhotoUrl;

        })
    }
}
