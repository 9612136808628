import { Component, OnInit } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit';

@Component({
  selector: 'app-small-imgs-modal',
  templateUrl: './small-imgs-modal.component.html',
  styleUrls: ['./small-imgs-modal.component.css'],
})
export class SmallImgsModalComponent implements OnInit {
  constructor(public modalRef: MdbModalRef<SmallImgsModalComponent>) { }

  ngOnInit(): void { }
}
