import {
    Component,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.css'],
})

export class PricingComponent implements OnInit {
    constructor(
        public router: Router
    ) {

    }

    ngAfterViewInit(): void {

    }

    async ngOnInit(): Promise<void> {

    }
}
