<div style="padding-top: 5px; text-align: center;">
    <div class="container">
        <div class="row">
            <div class="col-12 upload-big">
                <div style="display: flex;">
                    <h2>{{ 'uploadBigImage' | translate }}</h2>
                </div>

                <ngx-dropzone *ngIf="!bigImgSelectedUrl" class="dropzone" (change)="onFileDropped($event)">
                    <ngx-dropzone-label>{{ 'dropHere' | translate }}</ngx-dropzone-label>
                </ngx-dropzone>

                <div class="inputs">
                    <label for="formFileSm" class="form-label"></label>
                    <input class="form-control form-control-sm" id="formFileSm" type="file"
                        (change)='onFileSelected($event)' accept="image/*" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 big-img-text">
                <p *ngIf='bigImgSelectedUrl'>{{bigImgStatus | translate}}</p>
                <p *ngIf='bigImgLoadingProgress'>{{ 'uploadProgress' | translate }}{{ bigImgLoadingProgress}}</p>
            </div>
        </div>
        <div class="row">
            <div *ngIf='bigImgSelectedUrl' class="col-12 big-img-container">
                <img class="img-thumbnail shadow-5-strong" alt="" [src]='bigImgSelectedUrl'
                    (load)='onImageLoaded($event)'>
            </div>
        </div>
        <div *ngIf='bigImgSelectedUrl'>
            <button mdbBtn type="button" color="indigo" size="lg"
                (click)=' createTogether(40)'>{{previewTextImg[40]}}</button>
            <button mdbBtn type="button" color="indigo" size="lg"
                (click)='createTogether(50)'>{{previewTextImg[50]}}</button>
            <button mdbBtn type="button" color="indigo" size="lg"
                (click)='createTogether(60)'>{{previewTextImg[60]}}</button>
        </div>
        <div *ngIf="link">
            Share this link for mosaic colaboration:<br>
            <span style="text-decoration: underline; color: blue; cursor: pointer;"
                onclick="copyTheLink()">{{link}}</span>
        </div>
    </div>
</div>