import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UploadService {
  constructor(private http: HttpClient) { }

  uploadBigImage(file): Observable<any> {
    const fd = new FormData();
    fd.append('image', file);
    console.log(file);
    return this.http.post(
      'https://api.mozlike.com/v1.0/upload?uploadApiKey=mozlike123&path=tipaDareBig',
      fd,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  uploadSmallImages(file, path): Observable<any> {
    const fd = new FormData();
    fd.append('image', file);
    return this.http.post(
      `https://api.mozlike.com/v1.0/upload?uploadApiKey=mozlike123&path=${path}`,
      fd,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  generate(body): Observable<any> {
    return this.http.post('https://api.mozlike.com/v1.0/urls', body, {
      reportProgress: true,
      observe: 'events',
    });
  }

  enlarge(name): Observable<any> {
    return this.http.post(
      'https://api.mozlike.com/v1.0/enlarge',
      { name: name },
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  updateUserData(body): Observable<any> {
    console.log(body);
    return this.http.put(
      'https://api.mozlike.com/v1.0/updateUserData',
      body,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  getUserData(email): Observable<any> {
    return this.http.get(
      'https://api.mozlike.com/v1.0/getUserData?email=' + email
    );
  }

  getMozlike(id): Observable<any> {
    return this.http.get(
      'https://api.mozlike.com/v1.0/mozlikes?id=' + id
    );
  }
}
