<footer class="page-footer font-small background">

  <!-- Footer Elements -->
  <div class="container">

    <!-- Grid row-->
    <div class="row">

      <!-- Grid column -->
      <div class="col-md-12 py-5">
        <div class="mb-5 flex-center">

          <!-- Facebook -->
          <a class="fb-ic" href="https://www.facebook.com/mosaicartfactory">
            <mdb-icon fab icon="facebook" size="2x" class="white-text mr-3"></mdb-icon>
          </a>
          <!-- Twitter -->
          <!-- <a class="tw-ic" href="https://twitter.com/_moz_like_">
            <mdb-icon fab icon="twitter" size="2x" class="white-text mr-md-5 mr-3"></mdb-icon>
          </a> -->
          <!--Instagram-->
          <a class="ins-ic" href="https://www.instagram.com/mozlike.official/">
            <mdb-icon fab icon="instagram" size="2x" class="white-text mr-3"></mdb-icon>
          </a>

          <!--Pinterest-->
          <!-- <a href="https://opensea.io/MJ23">
            <img size="2x" class="white-text mr-md-5 mr-3" src="../../../../assets/img/opensea.png" alt="">
          </a> -->
        </div>
      </div>
      <!-- Grid column -->

    </div>
    <mat-card style="background-color: transparent; text-align: center; color: white;box-shadow: none;">
      <mdb-icon fas icon="envelope" size="1x" class="mr-0"></mdb-icon>
      <span>marko.jereminov@mozlike.com</span>
    </mat-card>
    <!-- Grid row-->

  </div>
  <!-- Footer Elements -->

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">
    <a href="#">© mozlike.com</a>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->