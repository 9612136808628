import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TogetherService {
    constructor(private http: HttpClient) { }

    createTogether(body): Observable<any> {
        return this.http.post('https://api.mozlike.com/v1.0/createTogether', body, {
            reportProgress: true,
            observe: 'events',
        });
    }

    addSmall(body): Observable<any> {
        return this.http.post('https://api.mozlike.com/v1.0/addSmall', body, {
            reportProgress: true,
            observe: 'events',
        });
    }

    getTogether(id): Observable<any> {
        return this.http.get(
            'https://api.mozlike.com/v1.0/getTogether?id=' + id
        );
    }
}
