import {
  AfterViewChecked,
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MozlikePhoto } from 'src/app/models/MozlikePhoto.model';
const OpenSeadragon = require('openseadragon');

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
})
export class CardComponent implements OnInit, AfterViewInit {
  //check if IOS device
  isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  ngAfterViewInit(): void {
    for (let i = 0; i < this.photos.length; i++) {
      let viewer = new OpenSeadragon({
        id: `openseadragon${i}`,
        prefixUrl: '../../assets/img/',
        defaultZoomLevel: 0,
        minZoomLevel: 0,
        visibilityRatio: 1,
        useCanvas: !this.isIOS,
        ensureVisible: true,
        tileSources: {
          type: 'image',
          url: this.photos[i].url,
        },
      });

      this.viewers.push(viewer);
    }
  }

  photos: MozlikePhoto[] = [
    {
      name: '',
      description: '',
      price: 1,
      title: 'Bleki',
      url: 'https://static.sajtzadan.com/blekki23.webp',
    },
    {
      name: '',
      description: '',
      price: 100,
      title: 'Agelast',
      url: 'https://static.sajtzadan.com/galebb.webp',
    },
    {
      name: '',
      description: '',
      price: 100,
      title: 'TijanaJane',
      url: '../../assets/img/tijanajane.png',
    },
  ];

  viewers = [];

  constructor() {}

  ngOnInit(): void {
    /* this.smallImage = '../../assets/img/face2-small.png'
    this.regularImage = 'https://static.sajtzadan.com/moslike/faca2.webp' */
    console.log(this.photos);

    console.log(this.viewers);
    /*  
  var viewer = new OpenSeadragon({
    id: "openseadragon1", //["openseadragon1", "openseadragon2"] nece ni ovo
    prefixUrl: "../../assets/img/",
    defaultZoomLevel: 1,
    minZoomLevel: 1,
    visibilityRatio: 1,
    ensureVisible: true,
    sequenceMode: true,
    tileSources: {
      type: 'image',
      url:  'https://static.sajtzadan.com/moslike/faca2.webp'
    }
  });

  console.log(viewer);
  // viewer.on("tile-loaded",() => {
  //   console.log('loaded');
  // });
  var viewer1 = new OpenSeadragon({
    id: "openseadragon2",
    prefixUrl: "../../assets/img/",
    defaultZoomLevel: 1,
    minZoomLevel: 1,
    visibilityRatio: 1,
    ensureVisible: true,
    tileSources: {
      type: 'image',
      url:  '../../assets/img/tijanajane.png'
    }
  });
  // viewer1.on("tile-loaded",() => {
  //   console.log('loaded2');
  // });
   */
  }

  images = [
    'https://static.sajtzadan.com/moslike/faca2.webp',
    '../../assets/img/canvas.png',
    '../../assets/img/tijanajane.png',
    '../../assets/img/canvas.png',
    '../../assets/img/download.png',
    '../../assets/img/f4.png',
    '../../assets/img/mj23.png',
    '../../assets/img/pryanca.png',
    '../../assets/img/mj.jpg',
  ];

  /* prev = document.getElementById('prev');
  next = document.getElementById('next');
  

  counter = 0;
  imgPath = this.images[this.counter];

  zoomIn() {
    this.myPinch.zoomIn();
  }

  onPrevClick(){
    if(this.counter === 0){
      this.counter = this.images.length - 1;
    }else{
      this.counter--;
    }
    this.imgPath = this.images[this.counter];
  }

  onNextClick(){
    if(this.counter === this.images.length - 1){
      this.counter = 0;
    }
    else{
      this.counter++;
    }
    this.imgPath = this.images[this.counter];
  }
 */
}
