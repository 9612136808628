<!-- <header>
  <mdb-navbar SideClass="navbar navbar-lg navbar-dark fixed-top scrolling-navbar navbar-expand-lg">
    <mdb-navbar-brand class="ml-1">
      <a class="logo navbar-brand waves-light" style="width: auto;" mdbWavesEffect href="#"><strong>Mozlike</strong></a>
    </mdb-navbar-brand>
    <mat-form-field class="langSelect" appearance="standard">
      <mat-select [(value)]="selectedValue" [(ngModel)]="selectedValue" (selectionChange)="changeLang()"
        [compareWith]="compareColors">
        <mat-select-trigger>
          <img with="20" height="20" [src]="selected.flag">
        </mat-select-trigger>
        <mat-option *ngFor=" let lang of languages" [value]="lang.code">
          <img with="20" height="20" [src]="lang.flag">
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button class="mr-1 btn btn-primary btn-rounded btn-sm" (click)="authService.SignOut()"
      *ngIf="authService.isLoggedIn">{{ 'logout' | translate }}</button>
    <button class="mr-auto btn btn-primary btn-rounded btn-sm" (click)="router.navigate(['profile'])"
      *ngIf="authService.isLoggedIn && !authService.isAnonymous">{{ 'profile' | translate }}</button>
    <button class="mr-auto btn btn-primary btn-rounded btn-sm" (click)="router.navigate(['sign-in'])"
      *ngIf="!authService.isLoggedIn">{{ 'signIn' | translate }}</button>
  </mdb-navbar>
</header> -->
<header>
  <nav class="custom-nav navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">

      <a style="filter: drop-shadow(2px 4px 4px black);" class="navbar-brand" href="#">
        <img style="margin: 0;" src="../../assets/img/logo2.png" alt="" width="30" height="24"><b>ozLike</b>
      </a>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">

          <li *ngIf="authService.isLoggedIn" class="nav-item">
            <a class="nav-link" routerLink="/upload">
              <mdb-icon fas icon="image" size="1x" class="mr-0"></mdb-icon>{{ 'create' | translate }}
            </a>
          </li>
          <li *ngIf="authService.isLoggedIn" class="nav-item">
            <a class="nav-link" routerLink="/profile">
              <mdb-icon fas icon="user" size="1x" class="mr-0"></mdb-icon>{{ 'profile' | translate }}
            </a>
          </li>
          <li *ngIf="!authService.isLoggedIn" class="nav-item">
            <a class="nav-link" routerLink="/sign-in">
              <mdb-icon fas icon="lock-open" size="1x" class="mr-0"></mdb-icon>{{ 'signIn' | translate }}
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/gallery">
              <mdb-icon fas icon="images" size="1x" class="mr-0"></mdb-icon>{{ 'gallery' | translate }}
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/pricing">
              <mdb-icon fas icon="money-check" size="1x" class="mr-0"></mdb-icon>{{ 'pricing' | translate }}
            </a>
          </li>
          <li *ngIf="authService.isLoggedIn" class="nav-item">
            <a class="nav-link" (click)='authService.SignOut()'>
              <mdb-icon fas icon="lock" size="1x" class="mr-0"></mdb-icon>{{ 'logout' | translate }}
            </a>
          </li>
          <li class="nav-item">
            <mat-form-field class="langSelect" appearance="standard">
              <mat-select [(value)]="selectedValue" [(ngModel)]="selectedValue" (selectionChange)="changeLang()"
                [compareWith]="compareColors">
                <mat-select-trigger>
                  <img with="20" height="20" [src]="selected.flag">
                </mat-select-trigger>
                <mat-option *ngFor=" let lang of languages" [value]="lang.code">
                  <img with="20" height="20" [src]="lang.flag">
                </mat-option>
              </mat-select>
            </mat-form-field>
          </li>


        </ul>
      </div>
    </div>
  </nav>
</header>