<div class="stepper-wrapper">
  <iframe #myIframe style="display:none;"></iframe>
  <mat-horizontal-stepper #stepper class="stepper" style="padding-top: 0px;" [linear]="isLinear"
    (selectionChange)="selectionChange($event)" [orientation]="(stepperOrientation | async)!">>
    <mat-step label="{{ 'uploadBig' | translate }}">
      <div class="container">
        <div class="row">
          <input *ngIf="korisnik==='marko'" type="file" (change)="onJsonChange($event)" #fileInput>
          <div class="col-12 upload-big">
            <div style="display: flex;">
              <h2>{{ 'uploadBigImage' | translate }}</h2>
              <i class=" fas fa-info-circle" style="cursor: pointer;" (click)='openModal()'></i>
            </div>

            <ngx-dropzone *ngIf="!bigImgSelectedUrl" class="dropzone" (change)="onFileDropped($event)">
              <ngx-dropzone-label>{{ 'dropHere' | translate }}</ngx-dropzone-label>
            </ngx-dropzone>

            <div class="inputs">
              <label for="formFileSm" class="form-label"></label>
              <input class="form-control form-control-sm" id="formFileSm" type="file" (change)='onFileSelected($event)'
                accept="image/*" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 big-img-text">
            <p *ngIf='bigImgSelectedUrl'>{{bigImgStatus | translate}}</p>
            <p *ngIf='bigImgLoadingProgress'>{{ 'uploadProgress' | translate }}{{ bigImgLoadingProgress}}</p>
          </div>
        </div>
        <div class="row">
          <div *ngIf='bigImgSelectedUrl' class="col-12 big-img-container">
            <img class="img-thumbnail shadow-5-strong" alt="" [src]='bigImgSelectedUrl' (load)='onImageLoaded($event)'>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step label="{{ 'uploadSmall' | translate }}">
      <div class="container">
        <div class="row" style="text-align: center;">
          <h2>{{ 'uploadSmallImages' | translate }}</h2>
          <i class=" fas fa-info-circle" style="cursor: pointer;" (click)='openSmallImgsModal()'></i>
          <br>
          <br>
          <div class="upload-small">

            <div class="col-md-4 col-sm-12 col-xs-12">
              <p>{{ 'chooseSampleImages' | translate }}</p>
              <button [disabled]=" !enableUrbanArtBtn" class="btn btn-outline-primary btn-rounded btn-sm"
                (click)='applySample("urbanArt")'>Urban
                ART</button>
            </div>
            <br>
            <!-- <div *ngIf="!isAnonymous" class="col-md-3 col-sm-12 col-xs-12">
              <p>{{ 'instagramUpload' | translate }}:</p>
              <button style=" background-color: #d62976!important;color:white" class="btn btn-rounded btn-sm"
                (click)='instagramAuth()'>
                <mdb-icon fab icon="instagram" size="2x"></mdb-icon>
              </button>
            </div> -->
            <br>
            <div class="col-md-4 col-sm-12 col-xs-12">
              <p style="margin: 0!important;">{{ 'uploadFromDevice' | translate }}</p>
              <label for="formFileSm" class="form-label"></label>
              <input class="form-control form-control-sm" id="formFileSm" type="file" (change)='onFilesSelected($event)'
                multiple accept="image/*" />

            </div>
          </div>
          <div class="inputs">

            <button *ngIf='smallImgSelectedUrls.length !== 0' class="btn btn-outline-primary btn-rounded btn-sm"
              [disabled]='allowClear' (click)='clearLocalSorage()'>{{ 'clear' | translate }}</button>
            <div class="col-12">
              <div class="small-img-text">
                <p>{{smallImgsStatus | translate}}</p>
                <p *ngIf='smallImgsPreviewMessage'>{{ smallImgsPreviewMessage }}</p>
                <p *ngIf='smallImgSelectedUrls.length !== 0'>
                  <span>{{'uploadedImagesCount' | translate}}</span> {{ counter + '/' + smallImgSelectedUrls.length}}
                </p>
              </div>
            </div>
            <button type="button" class="btn btn-outline-primary btn-rounded btn-sm"
              [disabled]='smallImgSelectedUrls.length === 0 || allowSmallUpload'
              (click)='onUploadSmall()'>{{ 'upload' | translate }}</button>
            <br>
            <mdb-form-control *ngIf='files.length !== 0' style="margin-top: 10px;">
              <input mdbInput type="text" id="formControlSm" class="form-control form-control-sm" [(ngModel)]='asset' />
              <label mdbLabel class="form-label" for="formControlSm">{{ 'assetName' | translate }}</label>
            </mdb-form-control>
            <br>
            <button (click)='stepper.next()' class="btn btn-primary btn-rounded btn-sm"
              *ngIf='smallImgsUploadedUrls.length !== 0 && bigImgUploadedUrl && !uploadGoingOn'>{{'next' | translate}}</button>
            <br>
          </div>
        </div>
        <div *ngIf='smallImgSelectedUrls.length !== 0' class="row">
          <div class="progress-wrapper">
            <div class="progress">
              <div class="progress-bar" [style.width]='smallImgsProgress' role="progressbar"
                [attr.aria-valuenow]="smallImgsProgress" aria-valuemin="0" aria-valuemax="100">{{ smallImgsProgress }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div *ngIf='smallImgSelectedUrls.length !==0 && !uploadGoingOn' class="col-12 small-imgs-preview"
            [ngClass]="{mobile: isSmallScreen()}">
            <div class="small-imgs-container" lazy-load-images
              *ngFor='let url of smallImgSelectedUrls.slice().reverse(); let i = index;'>

              <div class="card">
                <!-- <image-cropper *ngIf="url.indexOf('https://') < 0 && editingMode[i]"  class="card-img-top" [imageChangedEvent]="imageChangedEvent[i]" [maintainAspectRatio]="true" [aspectRatio]="1 / 1"
                format="png" (imageCropped)="imageCropped($event, i)" (loadImageFailed)="loadImageFailed()">
              </image-cropper> -->

                <img alt="" [attr.data-src]='url' class="card-img-top"
                  [style.width]='cardWH!==undefined ? cardWH : "100px"'
                  [style.height]='cardWH!==undefined ? cardWH : "100px"'
                  [ngStyle]="{'padding': '5px', 'object-fit': 'cover'}">
                <div class="card-body">
                  <div class="small-img-info">

                    <button type="button" class="close-btn btn btn-xs btn-outline-danger btn-floating btn-xs"
                      (click)='removeSmallImg(smallImgSelectedUrls.length-i-1)'>
                      <i class="fas fa-times"></i>
                    </button>
                    <p>{{ smallImgLoadingProgressMap[i] }}</p>
                    <button
                      *ngIf="url.indexOf('https://') < 0 && !editingMode[smallImgSelectedUrls.length-i-1] && !allowSmallUpload"
                      type="button" class="close-btn btn btn-xs btn-outline-success btn-floating"
                      (click)='startCropping(mymodal, i)'>
                      <i class="fas fa-edit"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf='smallImgSelectedUrls.length !==0 && uploadGoingOn' class="col-12 small-imgs-preview">
            <div class="spinner container">
              <mat-spinner [diameter]="100"></mat-spinner>
            </div>
          </div>
          <div style="display: flex;
          flex-direction: column;
          align-items: center;
          margin: 5px">
            <button (click)='stepper.next()' class="btn btn-primary btn-rounded btn-sm"
              *ngIf='smallImgsUploadedUrls.length !== 0 && bigImgUploadedUrl && !uploadGoingOn'>{{'next' | translate}}</button>
          </div>
          <br>
        </div>
      </div>
    </mat-step>
    <mat-step *ngIf='smallImgsUploadedUrls.length !== 0 && bigImgUploadedUrl' label="{{'create' | translate}}">
      <div class="container">
        <input [(ngModel)]="chosenName" (value)="chosenName" *ngIf="korisnik==='marko'" />
        <button (click)="getLargeImage()" *ngIf="korisnik==='marko'"></button>
        <div *ngIf="finalLoading" class="row">
          <div class="spinner">
            <mat-spinner [diameter]="100"></mat-spinner>
          </div>
        </div>
        <div class="row">
          <div class="col-12" style="text-align: center; display: grid; justify-content: center; margin-top: 10px;">
            <div #largeCanvasCard id="pcWrapper" class="large-canvas-container">

              <div *ngIf='finalDone === true && !paypalScreen && !finalScreen && !finalLoading'>
                {{ 'modifyOpacity' | translate }}</div>
              <!-- <mat-slider *ngIf='finalDone === true && !paypalScreen && !finalScreen && !finalLoading' [max]="0.5"
                [min]="0" [step]=".02" [(ngModel)]="sliderFilter" (change)="onReEnlarge()"></mat-slider> -->
              <div *ngIf='finalDone === true && !paypalScreen && !finalScreen && !finalLoading' class="options-btns">
                <button *ngIf="korisnik==='marko'" (click)='onReEnlarge(0, 23)'
                  [class]="selectedOpacity===23?'selectedOpacity':'notSelected'" mdbBtn type="button"
                  style="background-color: rgba(63, 81, 181, 0.15);" size="md" class="col-md-3">
                  {{ '0' | translate }}
                </button>
                <button (click)='onReEnlarge(0.15, 0)' [class]="selectedOpacity===0?'selectedOpacity':'notSelected'"
                  mdbBtn type="button" style="background-color: rgba(63, 81, 181, 0.15);" size="md" class="col-md-3">
                  {{ 'lowOpacity' | translate }}
                </button>
                <button (click)='onReEnlarge(0.23, 4)' [class]="selectedOpacity===4?'selectedOpacity':'notSelected'"
                  mdbBtn type="button" style="background-color: rgba(63, 81, 181, 0.25);" size="md" class="col-md-1">
                  {{ 'l' | translate }}
                </button>
                <button (click)='onReEnlarge(0.3, 1)' [class]="selectedOpacity===1?'selectedOpacity':'notSelected'"
                  mdbBtn type="button" style="background-color: rgba(63, 81, 181, 0.5);" size="md" class="col-md-3">
                  {{ 'mediumOpacity' | translate }}
                </button>
                <button (click)='onReEnlarge(0.38, 5)' [class]="selectedOpacity===5?'selectedOpacity':'notSelected'"
                  mdbBtn type="button" style="background-color: rgba(63, 81, 181, 0.7);" size="md" class="col-md-1">
                  {{ 'm' | translate }}
                </button>
                <button (click)='onReEnlarge(0.45, 2)' [class]="selectedOpacity===2?'selectedOpacity':'notSelected'"
                  mdbBtn type="button" style="background-color: rgba(63, 81, 181, 0.85);" size="md" class="col-md-3">
                  {{ 'highOpacity' | translate }}
                </button>
              </div>
              <br>
              <label *ngIf='finalDone === true && !paypalScreen && !finalScreen && !finalLoading'>
                {{ 'zoomInfo' | translate }}</label>
              <canvas style="display: initial;" *ngIf="!paypalScreen" height="0" id="panzoomCanvas2" #largeCanvas
                class="large-canvas"></canvas>
              <div *ngIf="finalDone === true && !paypalScreen && !finalScreen && !finalLoading" style="overflow: hidden;max-height: 50vh;border-style: ridge;
              border-color: rgba(63, 81, 181, 0.03);
              background-color: rgba(63, 81, 181, 0.03);">
                <img *ngIf="!paypalScreen" height="0" id="panzoomCanvas" [src]="largeImageSrc" />

              </div>
              <div class="card-body">
                <p style="color: #d62976"
                  *ngIf="!isAnonymous && firestoreUser?.tokens === 0 && finalDone === true && !paypalScreen && !finalScreen && !finalLoading">
                  {{ 'noTokensInfo' | translate }}
                </p>
                <p style="color: #d62976"
                  *ngIf="isAnonymous && finalDone === true && !paypalScreen && !finalScreen && !finalLoading">
                  {{ 'anonymousInfo' | translate }}
                </p>
                <p style="color: forestgreen"
                  *ngIf="!isAnonymous && firestoreUser?.tokens > 0 && finalDone === true && !paypalScreen && !finalScreen && !finalLoading">
                  {{ 'tokensInfo' | translate }} <span style="font-weight: bold;">{{firestoreUser?.tokens}}</span>
                </p>
                <button *ngIf="finalDone === true && !paypalScreen && !finalScreen && !finalLoading"
                  class="btn btn-outline-primary btn-rounded btn-sm"
                  style="color: white!important; background-color:rgba(56,142,60, 0.7)!important"
                  (click)="download()">{{ 'freeDownload' | translate }} ({{firestoreUser.tokens || 0}})</button>

                <!-- <button
                  *ngIf="finalDone === true && !paypalScreen && !finalScreen && !finalLoading && firestoreUser?.tokens === 0"
                  class="btn btn-outline-primary btn-rounded" (click)="removeWMAndDownload()"
                  style="color: white!important; background-color:rgba(56,142,60, 1)!important">{{ 'removeWatermark' | translate }}
                  -
                  $9</button> -->
                <button *ngIf="finalScreen === true" class="btn btn-outline-primary btn-rounded btn-sm"
                  (click)="download()">{{ 'download' | translate }}</button>
              </div>
            </div>
            <button *ngIf="paypalScreen" class="btn btn-outline-primary btn-rounded btn-sm"
              (click)="backToCanvas()">{{ 'back' | translate }}</button>
            <div *ngIf="paypalScreen" class="paypal-test">

            </div>
          </div>
        </div>

        <div *ngIf="loading" class="row">
          <div class="col-12 spinner" style="text-align: center;">
            <mat-progress-bar mode="query" class="col-8" *ngIf="loading"></mat-progress-bar>
            <br>
            <img src="../../assets/img/logo.gif" style="width: 300px;" alt="">
            <br>
            <p>{{ mosaicGeneratingMsg | translate }}</p>
          </div>
        </div>
        <br>
        <hr *ngIf="!loading">
        <div *ngIf='smallImgsUploadedUrls.length !== 0 && bigImgUploadedUrl && !loading' class="row">
          <div class="col-12">
            <div class="mosaic-size">
              <h3>{{ 'selectMosaicSize' | translate }}</h3>
              <p>{{ 'mosaicColRow' | translate }}</p>
            </div>
            <div class="options-btns">
              <button *ngIf="korisnik==='marko'" mdbBtn type="button" color="indigo" size="lg"
                (click)='openRepeatModal(mymodal2, 10)'>{{previewTextImg[10]}}</button>
              <button *ngIf="korisnik==='marko'" mdbBtn type="button" color="indigo" size="lg"
                (click)='openRepeatModal(mymodal2, 15)'>{{previewTextImg[15]}}</button>
              <button *ngIf="korisnik==='marko'" mdbBtn type="button" color="indigo" size="lg"
                (click)='openRepeatModal(mymodal2, 20)'>{{previewTextImg[20]}}</button>
              <button *ngIf="korisnik==='marko'" mdbBtn type="button" color="indigo" size="lg"
                (click)='openRepeatModal(mymodal2, 30)'>{{previewTextImg[30]}}</button>
              <button *ngIf="korisnik==='marko'" mdbBtn type="button" color="indigo" size="lg"
                (click)='openRepeatModal(mymodal2, 40)'>{{previewTextImg[40]}}</button>
              <button mdbBtn type="button" color="indigo" size="lg"
                (click)='openRepeatModal(mymodal2, 50)'>{{previewTextImg[50]}}</button>
              <button mdbBtn type="button" color="green" size="lg"
                (click)='openRepeatModal(mymodal2, 60)'>{{previewTextImg[60]}}<br><span>{{ 'recommendedSize' | translate }}</span></button>
              <button mdbBtn type="button" color="indigo" size="lg"
                (click)='openRepeatModal(mymodal2, 80)'>{{previewTextImg[80]}}</button>
            </div>
          </div>
          <div class="col-12">
            <!-- <h3>{{ 'selectMosaicSize' | translate }}</h3> -->
            <h3></h3>

          </div>
        </div>
        <hr *ngIf="!loading">
        <div style="text-align: center;">
          <h4>{{ 'moreInfo' | translate }}</h4>
          <i class=" fas fa-info-circle" style="cursor: pointer;" (click)='openCreateMosaicModal()'></i>
        </div>
      </div>

    </mat-step>
    <ng-template #mymodal2 let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">{{ 'settings' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('exit')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <h5 class="modal-title" id="modal-basic-title">{{ 'maxRepetition' | translate }}</h5>
        <input type="checkbox" (change)="modifyRepeat()" />&nbsp;
        <mat-slider *ngIf='!loading && repeatChecked' [max]="maxRepeat" [min]="minRepeat" [step]="1"
          [(ngModel)]="repeat"></mat-slider>
        <label *ngIf='!loading && repeatChecked'>{{repeat}}</label>
        <label *ngIf='!loading && !repeatChecked'>{{ 'unlimited' | translate }}</label>
        <hr>
        <h5 class="modal-title" id="modal-basic-title">{{ 'atleastoneTitle' | translate }}</h5><input type="checkbox"
          (change)="atleastoneModify()" />
      </div>
      <div class="modal-footer">
        <p>{{ 'helpInfo' | translate }}</p>
        <!-- <p>{{ 'emailInfo' | translate }}</p> -->
        <!-- <label>Email: </label><input type="text" [(ngModel)]="email"> -->
        <!-- <p></p> -->
        <button type="button" class="btn btn-outline-dark"
          (click)="modal.dismiss('continue')">{{ 'create' | translate }}</button>

      </div>
    </ng-template>
    <ng-template #mymodal let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ 'cropImage' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <image-cropper class="card-img-top" [onlyScaleDown]="true" [hideResizeSquares]="true"
          [maintainAspectRatio]="true" [aspectRatio]="1 / 1" [imageChangedEvent]="imageChangedEvent" format="png"
          (imageCropped)="imageCropped($event)">
        </image-cropper>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
      </div>
    </ng-template>

  </mat-horizontal-stepper>
  <div *ngIf='thirdStep'>
    <div class='row'>
      <div class="col-12" style="text-align: center;">
      </div>
    </div>
  </div>



</div>