import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {
    constructor(
        public authService: AuthService,
        public router: Router
    ) { }
    ngOnInit() {

    }
    ngAfterViewInit() {
        if (JSON.parse(localStorage.getItem("user")).emailVerified) {
            this.router.navigate(['upload']);
        }
    }
    refresh() {
        window.location.href = window.location.href;
    }
}