<div class="content" style="text-align: center;" (click)="closeModal($event)">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <div fxFlex="25%" fxFlex.xs="50%" fxFlex.sm="33%" *ngFor="let item of imagesData;">
            <mat-card class="mat-elevation-z4" style="text-align: center;">
                <div *ngIf="!item.loaded" class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only" id="loading"></span>
                    </div>
                </div>
                <img alt="loading..." (load)="onImgLoad(item.preview)" mat-card-image (click)="open(item.link, mymodal)"
                    class="modal-hover-opacity" [src]="item.preview">
            </mat-card>
        </div>

    </div>
    <button style="margin: 5px;" class="btn-primary" *ngIf="!done && isMobile" type="button" (click)="loadData()">
        load
    </button>
</div>
<ng-template #mymodal let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span style="font-size: 2em;" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" (touch)="closeModal($event)" (pan)="closeModal($event)" (click)="closeModal($event)"
        id="myWindow">
        <img (click)="closeModal($event)" src="{{imgSrc}}" id="imgz" />
    </div>
</ng-template>