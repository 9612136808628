<div class="stepper-wrapper">
    <mat-horizontal-stepper #stepper class="stepper" style="padding-top: 5px;" [linear]="isLinear"
        (selectionChange)="selectionChange($event)" [orientation]="(stepperOrientation | async)!">

        <mat-step label="{{ 'uploadSmall' | translate }}">
            <div class="container">
                <div class="row" style="text-align: center;">
                    <h2>{{ 'uploadSmallImages' | translate }}</h2>
                    <br>
                    <br>
                    <div class="upload-small">
                        <div class="col-md-3 col-sm-12 col-xs-12">
                            <p style="margin: 0!important;">{{ 'uploadFromDevice' | translate }}</p>
                            <label for="formFileSm" class="form-label"></label>
                            <input class="form-control form-control-sm" id="formFileSm" type="file"
                                (change)='onFilesSelected($event)' multiple accept="image/*" />
                        </div>
                    </div>
                    <div class="inputs">

                        <button *ngIf='smallImgSelectedUrls.length !== 0'
                            class="btn btn-outline-primary btn-rounded btn-sm" [disabled]='allowClear'
                            (click)='clearLocalSorage()'>{{ 'clear' | translate }}</button>
                        <div class="col-12">
                            <div class="small-img-text">
                                <p>{{smallImgsStatus | translate}}</p>
                                <p *ngIf='smallImgsPreviewMessage'>{{ smallImgsPreviewMessage }}</p>
                                <p *ngIf='smallImgSelectedUrls.length !== 0'>
                                    <span>{{'uploadedImagesCount' | translate}}</span>
                                    {{ counter + '/' + smallImgSelectedUrls.length}}
                                </p>
                            </div>
                        </div>
                        <button type="button" class="btn btn-outline-primary btn-rounded btn-sm"
                            [disabled]='smallImgSelectedUrls.length === 0 || allowSmallUpload'
                            (click)='onUploadSmall()'>{{ 'upload' | translate }}</button>
                        <br>
                        <mdb-form-control *ngIf='files.length !== 0' style="margin-top: 10px;">
                            <input mdbInput type="text" id="formControlSm" class="form-control form-control-sm"
                                [(ngModel)]='asset' />
                            <label mdbLabel class="form-label" for="formControlSm">{{ 'assetName' | translate }}</label>
                        </mdb-form-control>
                        <br>
                        <button (click)='stepper.next()' class="btn btn-primary btn-rounded btn-sm"
                            *ngIf='smallImgsUploadedUrls.length !== 0 && bigImgUploadedUrl && !uploadGoingOn'>{{'next' | translate}}</button>
                        <br>
                    </div>
                </div>
                <div *ngIf='smallImgSelectedUrls.length !== 0' class="row">
                    <div class="progress-wrapper">
                        <div class="progress">
                            <div class="progress-bar" [style.width]='smallImgsProgress' role="progressbar"
                                [attr.aria-valuenow]="smallImgsProgress" aria-valuemin="0" aria-valuemax="100">
                                {{ smallImgsProgress }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf='smallImgSelectedUrls.length !==0 && !uploadGoingOn' class="col-12 small-imgs-preview"
                        [ngClass]="{mobile: isSmallScreen()}">
                        <div class="small-imgs-container" lazy-load-images
                            *ngFor='let url of smallImgSelectedUrls.slice().reverse(); let i = index;'>

                            <div class="card">
                                <!-- <image-cropper *ngIf="url.indexOf('https://') < 0 && editingMode[i]"  class="card-img-top" [imageChangedEvent]="imageChangedEvent[i]" [maintainAspectRatio]="true" [aspectRatio]="1 / 1"
                      format="png" (imageCropped)="imageCropped($event, i)" (loadImageFailed)="loadImageFailed()">
                    </image-cropper> -->

                                <img alt="" [attr.data-src]='url' class="card-img-top"
                                    [style.width]='cardWH!==undefined ? cardWH : "100px"'
                                    [style.height]='cardWH!==undefined ? cardWH : "100px"'
                                    [ngStyle]="{'padding': '5px', 'object-fit': 'cover'}">
                                <div class="card-body">
                                    <div class="small-img-info">

                                        <button type="button"
                                            class="close-btn btn btn-xs btn-outline-danger btn-floating btn-xs"
                                            (click)='removeSmallImg(smallImgSelectedUrls.length-i-1)'>
                                            <i class="fas fa-times"></i>
                                        </button>
                                        <p>{{ smallImgLoadingProgressMap[i] }}</p>
                                        <button
                                            *ngIf="url.indexOf('https://') < 0 && !editingMode[smallImgSelectedUrls.length-i-1] && !allowSmallUpload"
                                            type="button" class="close-btn btn btn-xs btn-outline-success btn-floating"
                                            (click)='startCropping(mymodal, i)'>
                                            <i class="fas fa-edit"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf='smallImgSelectedUrls.length !==0 && uploadGoingOn' class="col-12 small-imgs-preview">
                        <div class="spinner container">
                            <mat-spinner [diameter]="100"></mat-spinner>
                        </div>
                    </div>
                    <div style="display: flex;
                flex-direction: column;
                align-items: center;
                margin: 5px">
                        <button (click)='stepper.next()' class="btn btn-primary btn-rounded btn-sm"
                            *ngIf='smallImgsUploadedUrls.length !== 0 && bigImgUploadedUrl && !uploadGoingOn'>{{'next' | translate}}</button>
                    </div>
                    <br>
                </div>
            </div>
        </mat-step>
        <mat-step label="{{ 'preview' | translate }}">
            <div *ngIf='!isFinished' class="col-12 small-imgs-preview">
                <div class="spinner container">
                    <mat-spinner [diameter]="100"></mat-spinner>
                </div>
            </div>
            <div style="text-align: center;" class="container">
                <div class="row">
                    <div class="col-12"
                        style="text-align: center; display: grid; justify-content: center; margin-top: 10px;">
                        <div #largeCanvasCard id="pcWrapper" class="large-canvas-container">
                            <canvas style="display: initial;" height="0" id="panzoomCanvas2" #largeCanvas
                                class="large-canvas"></canvas>
                            <div *ngIf='isFinished' style="overflow: hidden;max-height: 80vh;border-style: ridge;
              border-color: rgba(63, 81, 181, 0.03);
              background-color: rgba(63, 81, 181, 0.03);">
                                <img height="0" id="panzoomCanvas" [src]="largeImageSrc" />
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <button *ngIf='isFinished' mdbBtn type="button" color="indigo" size="lg"
                    (click)='refresh()'>Refresh</button>
            </div>
        </mat-step>
        <ng-template #mymodal let-modal>
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">{{ 'cropImage' | translate }}</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <image-cropper class="card-img-top" [onlyScaleDown]="true" [hideResizeSquares]="true"
                    [maintainAspectRatio]="true" [aspectRatio]="1 / 1" [imageChangedEvent]="imageChangedEvent"
                    format="png" (imageCropped)="imageCropped($event)">
                </image-cropper>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
            </div>
        </ng-template>
    </mat-horizontal-stepper>

</div>