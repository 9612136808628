import { environment } from '../environments/environment';
import { GalleryComponent } from './gallery/gallery.component';
import { NgModule } from '@angular/core';
import { NgbModule, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MdbModule } from 'mdb-angular-ui-kit';
import { MDBBootstrapModule, NavbarModule } from 'angular-bootstrap-md';
import { MdbModalModule } from 'mdb-angular-ui-kit';
import { ImageCropperModule } from 'ngx-image-cropper';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CardComponent } from './views/card/card.component';
import { HeaderComponent } from './views/navigation/header/header.component';
import { SidenavListComponent } from './views/navigation/sidenav-list/sidenav-list.component';
import { FooterComponent } from './views/footer/footer/footer.component';
import { DescriptionComponent } from './views/description/description.component';
import { RoutingModule } from './routing/routing.module';
import { HomeComponent } from './views/home/home.component';
import { UploadComponent } from './upload/upload.component';
import { ModalComponent } from './modal/modal.component';
import { SmallImgsModalComponent } from './modal/small-imgs-modal/small-imgs-modal.component';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgZoomModule } from 'ng-zoom';
import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CreateModalComponent } from './modal/create-modal/create-modal.component';
import { ConfirmationComponent } from './views/confirmation/confirmation.component';
import { AlertModalComponent } from './modal/alert-modal/alert-modal.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TogetherComponent } from './together/together.component';
import { ShareComponent } from './together-push/share.component';
import { ProfileComponent } from './profile/profile.component';
import { AnimateComponent } from './animate/animate.component';
import { PricingComponent } from './pricing/pricing.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    CardComponent,
    HeaderComponent,
    SidenavListComponent,
    FooterComponent,
    DescriptionComponent,
    HomeComponent,
    UploadComponent,
    ModalComponent,
    SmallImgsModalComponent,
    CreateModalComponent,
    GalleryComponent,
    ConfirmationComponent,
    AlertModalComponent,
    PrivacyComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    TogetherComponent,
    ShareComponent,
    ProfileComponent,
    AnimateComponent,
    PricingComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    RoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MdbModule,
    MdbModalModule,
    NgbModule,
    ImageCropperModule,
    LazyLoadImagesModule,
    MatSnackBarModule,
    NavbarModule,
    MDBBootstrapModule.forRoot(),
    NgZoomModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    NgxDropzoneModule
  ],
  exports: [TranslateModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
