import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
    Component,
    OnInit,
    ChangeDetectorRef,
    HostListener,
} from '@angular/core';
import { GalleryService } from '../services/gallery.service';
import panzoom from 'panzoom';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.css'],
})

export class GalleryComponent implements OnInit {
    imgSrc = "https://images.sajtzadan.com/https://static.sajtzadan.com/mozlike/deskbird2.jpeg";
    isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
            navigator.userAgent
        );
    imagesData: { link, name, loaded, preview }[] = [];
    z;
    offset = 0;
    done = false;
    constructor(
        private galleryService: GalleryService,
        private modalService: NgbModal,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
    }
    closeModal(event) {
        console.log("event");
        console.log(event);
        if (event.target.id === "imgz" || event.target.className === "mat-card-image modal-hover-opacity") {
            console.log("imgz indeed");
            return;
        }
        this.modalService.dismissAll();
    }
    open(url, content: any) {
        this.imgSrc = url.replace("?w=500&h=500", "");
        const ref = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' })

        if (this.z) this.z.destroy();
        ref.shown.subscribe(e => {
            document.getElementsByClassName("modal-content")[0].classList.add("modal-contentt");
            const element = document.getElementById('imgz')
            const pz = panzoom(element, {
                bounds: false,
                // boundsPadding: 0.5,
                maxZoom: 15,
                minZoom: 0.5
            })
        })

    }
    onImgLoad(link) {
        for (let index = 0; index < this.imagesData.length; index++) {
            const element = this.imagesData[index];
            if (element.preview === link) {
                this.imagesData[index].loaded = true;
                this.changeDetectorRef.detectChanges();
                break;
            }
        }
    }
    ngAfterViewInit(): void {

    }

    async ngOnInit(): Promise<void> {
        document.getElementsByTagName('nav')[0].style.backgroundColor = '#3f51b5';
        this.galleryService.getLinks(this.offset).subscribe(e => {
            const data = e.data.map(d => { return { ...d, loaded: false } })
            this.imagesData = this.imagesData.concat(data);
            console.log(this.imagesData);
            this.changeDetectorRef.detectChanges();
        })
    }
    @HostListener("window:scroll", ["$event"])
    onWindowScroll() {
        //In chrome and some browser scroll is given to body tag
        let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
        let max = document.documentElement.scrollHeight;
        console.log("pos:" + pos)
        console.log("max:" + max)
        // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
        if (pos >= max && !this.done) {
            this.loadData();
        }
    }
    loadData() {
        this.offset += 8;
        console.log("calling");
        this.galleryService.getLinks(this.offset).subscribe(e => {
            this.imagesData = this.imagesData.concat(e.data);
            console.log("data: " + e.data.length);
            if (e.data.length === 0) {
                this.done = true;
            }
            this.changeDetectorRef.detectChanges();
        })
    }
}
