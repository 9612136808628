<div (keyup.enter)="authService.signUp(userEmail.value, userPwd.value)" class="displayTable" style="padding-top: 0px;">
  <div class="displayTableCell">
    <div class="authBlock">
      <h3>{{ 'signup' | translate }}</h3>
      <div class="formGroup">
        <input type="email" class="formControl" placeholder="Email Address" #userEmail required />
      </div>
      <div class="formGroup">
        <input type="password" class="formControl" placeholder="Password" #userPwd required />
      </div>
      <div class="formGroup">
        <input type="button" class="btn btnPrimary" value="Sign Up"
          (click)="authService.signUp(userEmail.value, userPwd.value)" />
      </div>
      <div class="formGroup">
        <span class="or"><span class="orInner">Or</span></span>
      </div>
      <!-- Continue with Google -->
      <div class="formGroup">
        <button type="button" class="btn bg-light google-login-btn" (click)="authService.GoogleAuth()">
          {{ 'continueWithGoogle' | translate }}
        </button>
      </div>
    </div>
    <div class="redirectToLogin">
      <span>{{ 'alreadyHaveAccount' | translate }}
        <span class="redirect" routerLink="/sign-in">{{ 'login' | translate }}</span></span>
    </div>
  </div>
</div>