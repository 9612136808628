import {
    Component,
    OnInit,
} from '@angular/core';

@Component({
    selector: 'app-gallery',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.css'],
})

export class PrivacyComponent implements OnInit {
    constructor(
    ) {
    }
    ngAfterViewInit(): void {

    }

    async ngOnInit(): Promise<void> {
        document.getElementsByTagName('nav')[0].style.backgroundColor = '#3f51b5';
    }
}
