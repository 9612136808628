import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TogetherService } from '../services/together.service';
import panzoom from 'panzoom';
import { StepperOrientation, StepperSelectionEvent } from '@angular/cdk/stepper';
import { Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { UploadService } from '../services/upload.service';
import { HttpEventType } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
    selector: 'app-together',
    templateUrl: './together.component.html',
    styleUrls: ['./together.component.css'],
})

export class TogetherComponent implements OnInit {
    imgSrc = "https://images.sajtzadan.com/https://static.sajtzadan.com/mozlike/deskbird2.jpeg";
    allowSmallUpload;
    uploadGoingOn;
    files = [];
    isFinished = false;
    finalBigImgsMap = {};
    smallImgLoadingProgressMap = {};
    editingMode = [];
    smallImgSelectedUrls = [];
    smallImgsPreviewMessage = '';
    smallImgsProgress;
    allowClear;
    smallImgsStatus = 'noImagesYet';

    finalBigImgs = [];
    counter = 0;
    isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
            navigator.userAgent
        );
    @ViewChild('largeCanvas')
    largeCanvas: ElementRef<HTMLCanvasElement>;
    @ViewChild('myIframe')
    myIframe: ElementRef<HTMLIFrameElement>;
    public context2: CanvasRenderingContext2D;
    imagesData: { link, name, loaded, preview }[] = [];
    z;
    imageChangedEvent: any = {
        target: {
            files: [],
        },
    };
    offset = 0;
    selectedW;
    ecoef = 10;
    selectedH;
    finalMozaics;
    l = 0;
    bigImgUploadedUrl;
    id;
    previousImgsLength;
    asset;
    blurCoef = 18;
    smallImgsUploadedUrls = [];
    stepperOrientation: Observable<StepperOrientation>;
    sliderFilter = 0.3;
    selectedSmallW;
    selectedSmallH;
    isLinear = false;
    done = false;
    panz;
    largeImageSrc;
    selectedCropIndex;
    imageBase64;
    selectedIndex;
    constructor(private ngModalService: NgbModal, private uploadService: UploadService, breakpointObserver: BreakpointObserver, private route: ActivatedRoute, private togetherService: TogetherService, private changeDetectorRef: ChangeDetectorRef) {

        this.stepperOrientation = breakpointObserver
            .observe('(min-width: 800px)')
            .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    }
    ngAfterViewInit(): void {

    }
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    selectionChange(event: StepperSelectionEvent) {
    }
    async download() {
        this.getTheFile();
    }
    async imageCropped(event: ImageCroppedEvent) {
        this.files[this.selectedCropIndex] = this.b64toBlob(
            event.base64.split(',')[1]
        );
        this.imageBase64 = event.base64;
    }
    startCropping(content, index) {
        this.selectedCropIndex = this.files.length - index - 1;
        this.selectedIndex = this.smallImgSelectedUrls.length - index - 1;
        console.log(this.selectedCropIndex);
        console.log(this.selectedIndex);
        const file = this.files[this.selectedCropIndex];
        console.log(file);
        this.imageChangedEvent.target.files = [this.files[this.selectedCropIndex]];
        this.ngModalService
            .open(content, { ariaLabelledBy: 'modal-basic-title' })
            .result.then(
                async (result) => {
                    this.editingMode[this.selectedIndex] = true;
                    // const blob = await this.resizeImage(
                    //   this.files[this.selectedCropIndex],
                    //   400,
                    //   400
                    // );
                    const reader = new FileReader();
                    reader.readAsDataURL(this.files[this.selectedCropIndex]);
                    const selInd = this.selectedIndex;
                    reader.onload = (ev) => {
                        this.smallImgSelectedUrls[selInd] = ev.target.result;
                    };
                },
                async (reason) => {
                    // this.editingMode[this.selectedIndex] = true;
                    // const blob = await this.resizeImage(
                    //   this.files[this.selectedCropIndex],
                    //   80,
                    //   80
                    // );
                    // const reader = new FileReader();
                    // reader.readAsDataURL(blob);
                    // const selInd = this.selectedIndex;
                    // reader.onload = (ev) => {
                    //   this.smallImgSelectedUrls[selInd] = ev.target.result;
                    // };
                    this.files[this.selectedCropIndex] = file;
                }
            );
    }
    getTheFile() {
        window.scrollTo(0, 0);
        this.changeDetectorRef.detectChanges()

        const el = this.context2.canvas;
        const that = this;
        el.toBlob(function (blob) {
            var link = document.createElement('a');
            link.setAttribute('href', URL.createObjectURL(blob));
            link.setAttribute('download', 'mozlike.jpeg');

            if (document.createEvent) {
                var evtObj = document.createEvent('MouseEvents');
                evtObj.initEvent('click', true, true);
                link.dispatchEvent(evtObj);
            } else if (link.click) {
                link.click();
            }
            that.changeDetectorRef.detectChanges()
            const element = document.getElementById('panzoomCanvas');
            that.panz = panzoom(element, {
                bounds: false,
                maxZoom: 10,
                minZoom: 0.25
            })
            that.changeDetectorRef.detectChanges()
        }, 'image/jpeg');
    }
    async ngOnInit(): Promise<void> {
        document.getElementsByTagName('nav')[0].style.backgroundColor = '#3f51b5';
        this.route.queryParams
            .subscribe(params => {
                console.log(params);
                this.id = params.id;
                this.refresh();
            }
            );
    }
    refresh() {
        this.isFinished = false;
        this.togetherService.getTogether(this.id).subscribe((e) => {
            const data = e;
            console.log(data);
            this.finalMozaics = data.finals;
            this.bigImgUploadedUrl = data.url;
            this.selectedSmallH = data.smallH;
            this.selectedSmallW = data.smallW;
            this.selectedH = data.height;
            this.selectedW = data.width;
            this.drawLarge();
        });
    }
    isSmallScreen() {
        if (window.innerWidth < 800) {
            return true;
        }
        return false;
    }
    drawLarge() {
        if (this.isIOS) {
            let ij = 2;
            while (ij * ij * this.selectedH * this.selectedW <= 16777200) {
                ij++;
                if (ij * ij * this.selectedH * this.selectedW > 16777200) {
                    ij--;
                    break;
                }
            }
            this.ecoef = ij;
        }
        else if (this.isMobile) {
            let ija = 2;
            while (ija * ija * this.selectedH * this.selectedW <= 28100000) {
                ija++;
                if (ija * ija * this.selectedH * this.selectedW > 28100000) {
                    ija--;
                    break;
                }
            }
            this.ecoef = ija;
        }
        this.largeCanvas.nativeElement.height = Math.floor(this.selectedH * this.ecoef);
        this.largeCanvas.nativeElement.width = Math.floor(this.selectedW * this.ecoef);
        this.context2 = this.largeCanvas.nativeElement.getContext('2d');
        this.changeDetectorRef.detectChanges();
        this.largeCanvas.nativeElement.style.display = 'none';
        this.context2.clearRect(0, 0, Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef));
        this.context2.fillStyle = 'white';
        this.context2.fillRect(0, 0, Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef));
        this.context2.globalAlpha = 1;
        this.l = 0;
        if (this.finalMozaics.length === 0) {
            let bimg = new Image();
            bimg.onload = () => {
                this.context2.globalAlpha = this.sliderFilter;
                this.context2.filter = 'blur(' + this.blurCoef + 'px)';
                this.context2.drawImage(
                    bimg,
                    0,
                    0,
                    Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef)
                );
                this.largeImageSrc = this.getLargeImageSrc();
                this.context2.filter = 'blur(0px)';

                this.isFinished = true;
                this.changeDetectorRef.detectChanges();
                const element = document.getElementById('panzoomCanvas');
                this.panz = panzoom(element, {
                    bounds: false,
                    maxZoom: 10,
                    minZoom: 0.25
                })
            };
            bimg.src = this.bigImgUploadedUrl.replace("?w=100&h=100", "");
            bimg.crossOrigin = "anonymous";
        }
        for (let i = 0; i < this.finalMozaics.length; i++) {
            const imgObj = {
                img: new Image(),
                x: this.finalMozaics[i].x,
                y: this.finalMozaics[i].y,
                color: this.finalMozaics[i].color,
            };
            imgObj.img.crossOrigin = 'anonymous';
            this.loadTheSmallImage(imgObj, i);
        }
    }
    getLargeImageSrc() {
        return this.context2.canvas.toDataURL("image/jpeg", 0.75);
    }
    loadTheSmallImage(imgObj, i) {
        imgObj.img.onload = () => {
            this.context2.globalAlpha = 1;
            this.context2.drawImage(imgObj.img, Math.floor(imgObj.x * this.ecoef), Math.floor(imgObj.y * this.ecoef), Math.floor(this.selectedSmallW * this.ecoef), Math.floor(this.selectedSmallW * this.ecoef)); // Or at whatever offset you like
            this.l++;
            this.changeDetectorRef.detectChanges();
            if (this.l === this.finalMozaics.length) {
                let bimg = new Image();
                bimg.onload = () => {
                    this.context2.globalAlpha = this.sliderFilter;
                    this.context2.filter = 'blur(' + this.blurCoef + 'px)';
                    this.context2.drawImage(
                        bimg,
                        0,
                        0,
                        Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef)
                    );
                    this.largeImageSrc = this.getLargeImageSrc();
                    this.context2.filter = 'blur(0px)';
                    this.isFinished = true;
                    this.changeDetectorRef.detectChanges()
                    const element = document.getElementById('panzoomCanvas');
                    this.panz = panzoom(element, {
                        bounds: false,
                        maxZoom: 10,
                        minZoom: 0.25
                    })

                };
                bimg.src = this.bigImgUploadedUrl.replace("?w=100&h=100", "");
                bimg.crossOrigin = "anonymous";

            }
        };
        imgObj.img.onerror = () => {
            this.loadTheSmallImage(imgObj, i);
        }
        imgObj.img.src = this.finalMozaics[i].url
            .replace(
                'w=' + this.selectedSmallW,
                'w=300'
            )
            .replace(
                'h=' + this.selectedSmallH,
                'h=300'
            );
    }
    blobToFile = (theBlob: Blob, fileName: string): File => {
        var b: any = { ...theBlob, lastModifiedDate: new Date(), name: fileName };
        return <File>theBlob;
    };
    resizeImage(file: File, maxWidth: number, maxHeight: number, setSmall?: boolean): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const image = new Image();


            image.onload = () => {
                const width = image.width;
                const height = image.height;

                if (width <= maxWidth && height <= maxHeight) {
                    resolve(file);
                }

                let newWidth;
                let newHeight;
                let maxWidthTemp = maxWidth;
                let maxHeightTemp = maxHeight;
                if (width < maxWidth) {
                    maxWidthTemp = width;
                }
                if (height < maxHeight) {
                    maxHeightTemp = height;
                }
                if (width <= height) {
                    newHeight = height * (maxWidthTemp / width);
                    newWidth = maxWidthTemp;
                } else {
                    newWidth = width * (maxHeightTemp / height);
                    newHeight = maxHeightTemp;
                }

                const canvas = document.createElement('canvas');
                canvas.width = newWidth;
                canvas.height = newHeight;

                const context = canvas.getContext('2d');
                context.imageSmoothingEnabled = true;

                context.drawImage(image, 0, 0, newWidth, newHeight);
                canvas.toBlob(resolve, file.type, 1);
            };
            image.onerror = reject;
            image.src = URL.createObjectURL(file);
        });

    }
    async onFilesSelected(e) {
        this.smallImgsPreviewMessage = 'Processing images...';
        this.uploadGoingOn = true;
        let length = e.target.files.length;
        if (e.target.files) {
            let count = 0;
            await Promise.all(
                [...e.target.files].map(async (fil: any) => {
                    try {
                        let file = fil;
                        const type = fil.type;
                        console.log("fil: " + fil)
                        // if (/heic/.test(type) || !type) {
                        //   console.log("this is heic: " + type);
                        //   try {
                        //     file = await heic2any({ blob: fil, toType: "image/jpeg", quality: 0, })
                        //   }
                        //   catch (e) {
                        //     file = fil;
                        //   };
                        // }
                        const blob = await this.resizeImage(file, 400, 400, true);

                        const another = await this.resizeImage(
                            this.blobToFile(blob, 'id' + Math.random),
                            80,
                            80
                        );
                        const reader = new FileReader();
                        reader.readAsDataURL(another);
                        reader.onload = (ev) => {
                            this.files.push(blob);
                            this.editingMode.push(false);
                            this.smallImgSelectedUrls.push(ev.target.result);
                            this.smallImgsPreviewMessage = '';
                            this.smallImgsProgress =
                                Math.round(
                                    (this.counter / this.smallImgSelectedUrls.length) * 100
                                ) + '%';
                            count++;

                            if (count === length) {
                                this.allowSmallUpload = false;
                                this.uploadGoingOn = false;
                            }
                        };
                    } catch (e) {
                        length--;
                        console.log(e);
                    }
                })
            );
        }
        else {
            this.smallImgsPreviewMessage = '';
        }
    }

    async onUploadSmall() {
        this.allowSmallUpload = true;
        this.uploadGoingOn = true;
        this.allowClear = true;
        this.smallImgsStatus = 'uploadingSmallImages';
        this.finalBigImgs = [];
        if (!this.asset) {
            this.asset = 'small-photos';
        }
        this.counter = this.smallImgsUploadedUrls.length;
        await Promise.all(this.files.map(async file => {
            this.uploadService.uploadSmallImages(file, this.asset).subscribe((e) => {
                if (e.type === HttpEventType.UploadProgress) {
                    this.smallImgLoadingProgressMap[this.counter] =
                        (e.loaded / e.total) * 100 + '%';
                } else if (e.type === HttpEventType.Response) {
                    this.smallImgsUploadedUrls.push(e.body.urls[0]);

                    this.finalBigImgsMap[e.body.urls[0]] = file;
                    this.smallImgLoadingProgressMap[this.counter] = '100%';
                    this.counter++;
                    this.smallImgsProgress =
                        Math.round(
                            (this.counter / this.smallImgSelectedUrls.length) * 100
                        ) + '%';
                }
                if (this.counter === this.smallImgSelectedUrls.length) {
                    this.smallImgSelectedUrls = this.smallImgsUploadedUrls.map((url) =>
                        url.replace('w=100', 'w=' + '500').replace('h=100', 'h=' + '500')
                    );
                    this.previousImgsLength = this.smallImgSelectedUrls.length;
                    this.smallImgsStatus = 'smallImagesUploaded';
                    this.uploadGoingOn = false;
                    this.allowClear = false;
                    this.isFinished = false;
                    this.togetherService.addSmall({
                        id: this.id,
                        urls: this.smallImgsUploadedUrls
                    }).subscribe((e) => {
                        if (e.type === HttpEventType.Response) {
                            console.log(e);
                            this.refresh()
                        }
                    });
                }
            });
            this.files = [];
        }))
    }
}
