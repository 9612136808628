import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable, from } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase';


@Injectable({ providedIn: 'root' })
export class AuthService {

    userData: firebase.default.User;
    constructor(
        private http: HttpClient,
        public afAuth: AngularFireAuth,
        public router: Router,
        public ngZone: NgZone // NgZone service to remove outside scope warning
    ) {
        console.log(router.url);
        // Setting logged in user in localstorage else null
        this.afAuth.onAuthStateChanged((user: firebase.default.User) => {
            if (user) {
                console.log(router.url);
                this.userData = user;
                localStorage.setItem('user', JSON.stringify(this.userData));
                JSON.parse(localStorage.getItem('user')!);
                if (user.emailVerified) {
                    if (router.url !== "/home" && router.url !== "/profile"
                        && router.url !== "/" && router.url !== "/privacy"
                        && router.url !== "/gallery"
                        && router.url !== "/pricing"
                        && !router.url.startsWith("/upload")
                        && !router.url.startsWith("/animate")
                        && !router.url.startsWith("/together")
                        && !router.url.startsWith("/share")
                        && router.url !== "/verify-email-address") {
                        window.location.href = window.location.href;
                    }
                }
                else {
                    this.router.navigate(['verify-email-address']);
                }
            } else {
                localStorage.setItem('user', null);
                JSON.parse(localStorage.getItem('user')!);
            }
        })
    }
    // Returns true when user is looged in and email is verified
    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user')!);
        return user !== null && (user.emailVerified || user.isAnonymous) ? true : false;
    }

    get isAnonymous(): boolean {
        const user = JSON.parse(localStorage.getItem('user')!);
        return user !== null && user.isAnonymous ? true : false;
    }
    // Sign in with Google
    GoogleAuth() {
        return this.AuthLogin(new firebase.default.auth.GoogleAuthProvider());
    }
    // Auth logic to run auth providers
    AuthLogin(provider: any) {
        const result = this.afAuth
            .signInWithPopup(provider)
            .then((result: firebase.default.auth.UserCredential) => {
                console.log(result);
            })
            .catch((error: any) => {
                window.alert(error);
            });
    }
    // Sign out
    SignOut() {
        return this.afAuth.signOut().then(() => {
            localStorage.removeItem('user');
            this.router.navigate(['/']);
        });
    }

    baseUrl = 'https://api.mozlike.com/v1.0/'
    getCodeUrl(): Observable<any> {
        return this.http.get(this.baseUrl + 'instagramAuth?uri=https://web.mozlike.com/upload');
    }

    getAccessToken(code): Observable<any> {
        return this.http.get(this.baseUrl + 'instagramAccessToken', {
            params: {
                code,
                uri: 'https://web.mozlike.com/upload'
            }
        });
    }

    getMedia(accessToken: string): Observable<any> {
        return this.http.get(this.baseUrl + 'instagramPosts', {
            params: {
                accessToken
            }
        });
    }

    signUp(email: string, password: string) {
        this.afAuth.createUserWithEmailAndPassword(email, password).then((result: firebase.default.auth.UserCredential) => {
            this.SendVerificationMail();
        })
            .catch((error: any) => {
                window.alert(error);
            });;
    }

    SendVerificationMail() {
        return this.afAuth.currentUser
            .then((u: any) => u.sendEmailVerification())
            .then(() => {
            });
    }
    // Reset Forggot password
    ForgotPassword(passwordResetEmail: string) {
        return this.afAuth
            .sendPasswordResetEmail(passwordResetEmail)
            .then(() => {
                window.alert('Password reset email sent, check your inbox.');
            })
            .catch((error) => {
                window.alert(error);
            });
    }

    SignIn(email: string, password: string) {
        return this.afAuth
            .signInWithEmailAndPassword(email, password)
            .then((result) => {
                console.log("regular signin")
            })
            .catch((error) => {
                window.alert(error.message);
            });
    }

    GuestSignIn() {
        return this.afAuth
            .signInAnonymously()
            .then((result) => {
                console.log("guest signin")
            })
            .catch((error) => {
                window.alert(error.message);
            });
    }


}
