<mat-nav-list>
    <!-- <a mat-list-item routerLink="#" (click)="onSideNavClose()">
        <mat-icon>home</mat-icon><span class="nav-caption">Home</span>
    </a>
    <a mat-list-item routerLink="#" (click)="onSideNavClose()">
        <mat-icon>send</mat-icon><span class="nav-caption">Send</span>
    </a>
    <a mat-list-item routerLink="#" (click)="onSideNavClose()">
        <mat-icon>login</mat-icon><span>LogIn</span>
    </a> -->
</mat-nav-list>