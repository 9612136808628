import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GalleryService {
    constructor(private http: HttpClient) { }

    getLinks(offset): Observable<any> {
        return this.http.get(
            `https://api.mozlike.com/v1.0/links?limit=8&offset=${offset}`
        );
    }
}
