import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit';
import { Component, ElementRef, OnInit, ViewChild, HostListener } from '@angular/core';
import { CreateModalComponent } from 'src/app/modal/create-modal/create-modal.component';
import { ModalComponent } from 'src/app/modal/modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  @HostListener('window:scroll', ['$event'])

  onWindowScroll() {
    let element = document.querySelector('.navbar') as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      document.getElementsByTagName('nav')[0].style.backgroundColor = '#3f51b5';
    } else {
      document.getElementsByTagName('nav')[0].style.backgroundColor = 'transparent';
    }
  }
  constructor(private modalService: MdbModalService) { }
  modalRef: MdbModalRef<ModalComponent>;
  ngOnInit(): void { }

  getBackgroundImg() {
    if (this.isSmallScreen()) {
      return 'url(../../assets/img/photo2.jpg)';
    }
  }

  openCreateMosaicModal() {
    this.modalRef = this.modalService.open(CreateModalComponent, {
      modalClass: 'modal-dialog-scrollable',
    });
  }

  isSmallScreen() {
    if (window.innerWidth < 800) {
      return true;
    }
    return false;
  }

  isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
      navigator.userAgent
    );
}
