import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { UploadService } from '../services/upload.service';
import * as uuid from 'uuid';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { ModalComponent } from '../modal/modal.component';
import { SmallImgsModalComponent } from '../modal/small-imgs-modal/small-imgs-modal.component';

import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
const OpenSeadragon = require('openseadragon');
import { loadScript } from '@paypal/paypal-js';
import { getSample } from './samples';
import { ActivatedRoute } from '@angular/router';
import panzoom from 'panzoom';
import heic2any from "heic2any";
import { CreateModalComponent } from '../modal/create-modal/create-modal.component';
import { AlertModalComponent } from '../modal/alert-modal/alert-modal.component';
import { AuthService } from '../services/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
})
export class UploadComponent implements OnInit {
  imageChangedEvent: any = {
    target: {
      files: [],
    },
  };
  uploadGoingOn = false;
  firestoreUser = { tokens: 0 };
  atleastone = false;
  isAnonymous = true;
  instagramAccessToken;
  instagramPhotosUploaded;
  largeImageSrc = "";
  mosaicFinalImage;
  paypalScreen = false;
  enableUrbanArtBtn = true;
  finalScreen = false;
  stepperOrientation: Observable<StepperOrientation>;
  isLinear = false;
  modalRef: MdbModalRef<ModalComponent>;
  bimgSaved;
  wmimgSaved;
  selectedOpacity = 1;
  mosaicGeneratingMsg = 'mosaicIsBeingCreated';
  korisnik;
  imEvent;
  imageBase64;
  bigImgSelected;
  selectedCropIndex;
  selectedIndex;
  img;
  dataUrl;
  bigImgSelectedUrl;
  bigImgWidthMap = {};
  bigImgHeightMap = {};
  bigImgUploadedUrl;
  bigImgLoadingProgress;
  bigImgStatus = 'uploadTheBig';
  sliderFilter = 0;
  finalDone = false;
  wmLoaded;
  bigLoaded;
  finalBigImgs = [];
  finalBigImgsMap = {};
  bigImageUploadedHieght;
  bigImageUploadedWidth;
  allowBigUpload = true;
  previousImgsLength = 0;
  editingMode = [];
  cardWH;
  blurCoef = 18;
  maxRepeat = 0;
  minRepeat = 0;
  asset;
  panz;
  files = [];
  smallImgLoadingProgressMap = {};
  smallImgSelectedUrls = [];
  smallImgsUploadedUrls = [];
  smallImgsStatus = 'noImagesYet';
  smallImgsProgress;
  counter = 0;
  finalMozaics: any = [];
  previewInfoText = false;
  allowSmallUpload = false;
  allowClear = false;
  smallImgWidthMap = {};
  smallImgHeightMap = {};
  ecoef = 15;
  filter = 0;
  repeat = 1000000;
  repeatChecked = false;
  previewImg;
  previewTextImg = {};
  smallImgsPreviewMessage;
  name;
  paypal;
  chosenName;
  final;
  viewer;
  email;
  @ViewChild('previewCanvas')
  myCanvas: ElementRef<HTMLCanvasElement>;
  public context: CanvasRenderingContext2D;
  @ViewChild('largeCanvasCard') canvasCard: ElementRef;
  @ViewChild('osd') osd: ElementRef;
  @ViewChild('largeCanvas')
  largeCanvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('myIframe')
  myIframe: ElementRef<HTMLIFrameElement>;
  public context2: CanvasRenderingContext2D;
  loading = false;
  includeFilters = "yes";
  l;
  finalLoading = false;
  selectedH = 0;
  selectedW = 0;
  selectedSmallH = 0;
  selectedSmallW = 0;
  orientationRatio = 1;
  droppedFiles = [];
  @ViewChild('stepper') private myStepper: MatStepper;
  thirdStep = false;

  isSmallScreen() {
    if (window.innerWidth < 800) {
      return true;
    }
    return false;
  }
  ratio;
  isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  isMobile =
    /Android|webOS|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
      navigator.userAgent
    );

  selectionChange(event: StepperSelectionEvent) {
    if (event.selectedStep.label === 'Create') {
      this.thirdStep = true;
    } else this.thirdStep = false;
  }

  openSnackBar() {
    this._snackBar.open("Please wait, your image will be downloaded soon", "", { duration: 5000 });
  }

  modifyRepeat() {
    this.repeatChecked = !this.repeatChecked;
    const cols = this.selectedW / this.selectedSmallW;
    const rows = this.selectedH / this.selectedSmallH;
    const totalMatrix = cols * rows;
    const totalImagesCount = this.smallImgsUploadedUrls.length;
    this.minRepeat = totalMatrix < totalImagesCount ? 1 : Math.ceil(totalMatrix / totalImagesCount);
    this.maxRepeat = this.minRepeat * 10;
    this.repeat = this.repeatChecked ? this.minRepeat : 1000000;
    this.changeDetectorRef.detectChanges();
  }
  atleastoneModify() {
    this.atleastone = !this.atleastone;
  }

  openRepeatModal(content, size) {
    if (this.korisnik === "marko") {
      this.selectedH = this.bigImgHeightMap[size];
      this.selectedW = this.bigImgWidthMap[size];
      this.selectedSmallH = this.smallImgHeightMap[size];
      this.selectedSmallW = this.smallImgWidthMap[size];
      this.repeatChecked = false;
      const that = this;
      this.ngModalService
        .open(content, { ariaLabelledBy: 'modal-basic-title' })
        .result.then(
          (result) => {
            console.log(open);
          },
          (reason) => {
            if (reason === "continue")
              that.onGenerate(size);
          }
        );
    }
    else
      this.onGenerate(size);
  }
  getLargeImageSrc() {
    return this.context2.canvas.toDataURL("image/jpeg", 0.75);
  }
  async download() {
    let tokens = this.firestoreUser?.tokens || 0;
    tokens--;
    if (tokens === -1) {
      this.getTheFile();
    }
    else {
      this.uploadService.updateUserData({
        email: this.authService.userData.email,
        width: this.bigImageUploadedWidth || 0,
        height: this.bigImageUploadedHieght || 0,
        urls: this.smallImgsUploadedUrls || [],
        basePhotoUrl: this.bigImgUploadedUrl,
        tokens
      }).subscribe(async e => {
        if (e.type === HttpEventType.Response) {
          this.firestoreUser.tokens = tokens;
          await this.reenlarge(this.sliderFilter, this.selectedOpacity, false);
          this.getTheFile();
        }
        console.log(e);
      });
    }

  }

  getTheFile() {
    window.scrollTo(0, 0);
    this.finalLoading = true;
    this.changeDetectorRef.detectChanges()

    const el = this.context2.canvas;
    const that = this;
    el.toBlob(function (blob) {
      var link = document.createElement('a');
      link.setAttribute('href', URL.createObjectURL(blob));
      link.setAttribute('download', 'mozlike.jpeg');

      if (document.createEvent) {
        var evtObj = document.createEvent('MouseEvents');
        evtObj.initEvent('click', true, true);
        link.dispatchEvent(evtObj);
      } else if (link.click) {
        link.click();
      }
      that.finalLoading = false;
      that.changeDetectorRef.detectChanges()
      const element = document.getElementById('panzoomCanvas');
      that.panz = panzoom(element, {
        bounds: false,
        maxZoom: 10,
        minZoom: 0.25
      })
      that.changeDetectorRef.detectChanges()
    }, 'image/jpeg');
  }

  async removeWMAndDownload() {
    this.paypalScreen = true;
    this.changeDetectorRef.detectChanges();
    this.final = '';
    window.scrollTo(0, 0);
    try {
      this.paypal = await loadScript({ "client-id": "AXhT0Pq7KAXZ8MsNgYsQgXV8831yw93Nv6K4RR7WsLs2GMYnrtRMRM9ZvackRbtaUvIOBs2BtCVO001D", "merchant-id": ["NMQUFLE72XEDU"] });
    } catch (error) {
      console.error("failed to load the PayPal JS SDK script", error);
    }

    if (this.paypal) {
      try {
        const that = this;
        await this.paypal.Buttons(
          {
            createOrder: function (data, actions) {
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: '20.00'
                  }
                }],
                application_context: {
                  shipping_preference: 'NO_SHIPPING'
                }
              });
            },
            onApprove: function (data, actions) {
              return actions.order.capture().then(function (details) {
                console.log(data);
                that.paypalScreen = false;
                that.finalScreen = true;
                that.changeDetectorRef.detectChanges();
                that.final = '';
                that.largeCanvas.nativeElement.height = Math.floor(that.selectedH * this.ecoef);
                that.largeCanvas.nativeElement.width = Math.floor(that.selectedW * this.ecoef);
                that.context2 = that.largeCanvas.nativeElement.getContext('2d');
                that.drawLarge(false);
              });

            }
          }
        ).render(".paypal-test");
      } catch (error) {
        console.error("failed to render the PayPal Buttons", error);
      }
    }
  }

  downloadSmall() {
    var canvasDataUrl = this.myCanvas.nativeElement
      .toDataURL()
      .replace(/^data:image\/[^;]*/, 'data:application/octet-stream'),
      link = document.createElement('a');

    link.setAttribute('href', canvasDataUrl);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', 'mozlike.png');
    if (document.createEvent) {
      var evtObj = document.createEvent('MouseEvents');
      evtObj.initEvent('click', true, true);
      link.dispatchEvent(evtObj);
    } else if (link.click) {
      link.click();
    }
  }
  getRatio(x, y) {
    if (x > y) {
      return Math.round((x / y + Number.EPSILON) * 100) / 100;
    } else if (y > x) {
      return Math.round((y / x + Number.EPSILON) * 100) / 100;
    } else return 1;
  }
  openModal() {
    this.modalRef = this.modalService.open(ModalComponent, {
      modalClass: 'modal-dialog-scrollable',
    });
  }

  openSmallImgsModal() {
    this.modalRef = this.modalService.open(SmallImgsModalComponent, {
      modalClass: 'modal-dialog-scrollable',
    });
  }

  openAlertModal() {
    this.modalRef = this.modalService.open(AlertModalComponent, {
      modalClass: 'modal-dialog-scrollable',
    });
  }

  openCreateMosaicModal() {
    this.modalRef = this.modalService.open(CreateModalComponent, {
      modalClass: 'modal-dialog-scrollable',
    });
  }
  instagramAuth() {
    const isInstagramApplied = localStorage.getItem("instagramPhotosUploaded") === "true";
    if (isInstagramApplied) {
      alert("You already uploaded photos from your instagram! Clear all images and try again!")
      return;
    }
    if (this.instagramAccessToken) {
      this.smallImgsPreviewMessage = 'Loading instagram images...';
      this.authService.getMedia(this.instagramAccessToken).subscribe(async (resp) => {
        const total = resp.data.filter((t: any) => t.media_type === "IMAGE" || (t.media_type === 'CAROUSEL_ALBUM' && t.media_url.indexOf("https://scontent") >= 0));
        const files = [];
        await Promise.all(total.map(async data => {
          const url = data.media_url;
          const id = data.id;
          files.push(await this.getFileFromUrl(url, id))
        }))
        this.onFilesSelected({ target: { files } })
      });
    }
    else {
      this.authService.getCodeUrl().subscribe((e) => {
        window.location.href = e.url;
      });
    }
  }
  constructor(
    private uploadService: UploadService,
    private authService: AuthService,
    private http: HttpClient,
    breakpointObserver: BreakpointObserver,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: MdbModalService,
    private ngModalService: NgbModal,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private firestore: AngularFirestore
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }
  updateSmallImagesSize() {
    console.log(document.getElementsByClassName('row')[0].clientWidth);
    this.cardWH = this.isMobile || this.isIOS
      ? '100px'
      : document.getElementsByClassName('row')[0].clientWidth / 10 - 5 + 'px';
    this.changeDetectorRef.detectChanges();
  }
  ngAfterViewInit(): void {

    const params = new URLSearchParams(window.location.search)
    const code = params.get("code");

    this.updateSmallImagesSize();
    if (code) {
      setTimeout(() => {
        this.myStepper.next();
      }, 2000);
    }


  }
  onFileDropped(event) {
    this.droppedFiles = [...event.addedFiles];
    this.onFileSelected({
      target: {
        files: [event.addedFiles[0]]
      }
    })
  }
  async ngOnInit(): Promise<void> {
    const email = JSON.parse(localStorage.getItem("user")).email
    if (email) {
      this.email = email;
      this.isAnonymous = false;
      this.uploadService.getUserData(email).subscribe((e) => {
        const firestoreUserData = e.data;
        this.firestoreUser = firestoreUserData;
        const params = new URLSearchParams(window.location.search)
        this.instagramAccessToken = localStorage.getItem("instagram_access_token");
        this.repeatChecked = false;

        this.korisnik = params.get('korisnik');
        // this.includeFilters = params.get("filters");
        this.repeat = +params.get('repeat') || this.repeatChecked ? this.repeat : 1000000;
        this.allowSmallUpload = true;
        const isUrbanArtApplied = localStorage.getItem('sample_urbanArt_Applied') !== "true";

        this.enableUrbanArtBtn = isUrbanArtApplied;
        document.getElementsByTagName('nav')[0].style.backgroundColor = '#3f51b5';
        const extractedBigImgHeight = firestoreUserData.height;//localStorage.getItem('bigImgHeigth');
        const extractedBigImgWidth = firestoreUserData.width;//localStorage.getItem('bigImgWidth');
        const extractedBigImgUploadedUrl = firestoreUserData.basePhotoUrl;
        //localStorage.getItem('bigImgUploadedUrl');
        const extractedSmallImgsUploaded = firestoreUserData.urls || [];//
        // JSON.parse(
        //   localStorage.getItem('smallUploadededUrls')
        // );
        if (extractedBigImgUploadedUrl) {
          this.bigImgUploadedUrl = extractedBigImgUploadedUrl;

          this.bigImgSelectedUrl = this.bigImgUploadedUrl
            .replace('w=100', 'w=' + extractedBigImgWidth)
            .replace('h=100', 'h=' + extractedBigImgHeight);
          this.bigImgStatus = 'bigImageConfirmation';
        }
        if (extractedSmallImgsUploaded && extractedSmallImgsUploaded.length !== 0) {
          this.smallImgsUploadedUrls = extractedSmallImgsUploaded;
          this.smallImgSelectedUrls = extractedSmallImgsUploaded.map((url, i) => {
            this.previousImgsLength++;
            this.editingMode[i] = false;
            return url
              .replace('w=100', 'w=' + '500')
              .replace('h=100', 'h=' + '500');
          });

          this.smallImgsStatus = 'smallImagesConfirmation';
          this.counter = this.smallImgsUploadedUrls.length;
          this.smallImgsProgress = '100%';
        } else {
          this.smallImgsProgress = '0%';
          this.counter = 0;
        }
        const code = params.get("code");
        if (code && !this.instagramAccessToken) {
          this.smallImgsPreviewMessage = 'Loading instagram images...';
          this.authService.getAccessToken(code).subscribe((e) => {
            const token = e.data["access_token"];
            localStorage.setItem('instagram_access_token', token);
            this.authService.getMedia(token).subscribe(async (resp) => {
              const total = resp.data.filter((t: any) => t.media_type === "IMAGE" || (t.media_type === 'CAROUSEL_ALBUM' && t.media_url.indexOf("https://scontent") >= 0));
              const files = [];
              await Promise.all(total.map(async data => {
                const url = data.media_url;
                const id = data.id;
                files.push(await this.getFileFromUrl(url, id))
              }))
              this.onFilesSelected({ target: { files } })
              localStorage.setItem("instagramPhotosUploaded", "true");
            });
          });
        }
        // if (!isUrbanArtApplied) {
        //   this.uploadService.updateUserData({
        //     email: email,
        //     width: this.bigImageUploadedWidth || 0,
        //     height: this.bigImageUploadedHieght || 0,
        //     urls: getSample('urbanArt'),
        //     basePhotoUrl: this.bigImgUploadedUrl || ""
        //   }).subscribe(e => {
        //   })
        // }
      });
    }
  }

  onImageLoaded(event) {
    console.log("onimageloaded");
    const sizes = [10, 15, 20, 30, 40, 50, 60, 80];
    this.bigImageUploadedHieght = event.target.naturalHeight;
    this.bigImageUploadedWidth = event.target.naturalWidth;
    localStorage.setItem('bigImgHeigth', this.bigImageUploadedHieght);
    localStorage.setItem('bigImgWidth', this.bigImageUploadedWidth);
    sizes.forEach((size) => {
      this.bigImgWidthMap[size] = this.bigImageUploadedWidth;
      this.bigImgHeightMap[size] = this.bigImageUploadedHieght;
      this.ratio = this.getRatio(
        this.bigImgWidthMap[size],
        this.bigImgHeightMap[size]
      );
      if (this.bigImgWidthMap[size] >= this.bigImgHeightMap[size]) {
        if (this.bigImgWidthMap[size] > 1000) {
          this.bigImgWidthMap[size] = 1000;
          this.bigImgHeightMap[size] = this.bigImgWidthMap[size] / this.ratio;
        }
        this.smallImgWidthMap[size] = Math.floor(
          this.bigImgWidthMap[size] / size
        );
        const tempSize = this.bigImgWidthMap[size] / size;
        this.smallImgHeightMap[size] = this.smallImgWidthMap[size];
        this.bigImgWidthMap[size] = size * this.smallImgWidthMap[size];
        this.bigImgHeightMap[size] =
          this.smallImgWidthMap[size] *
          Math.round(this.bigImgHeightMap[size] / tempSize);
      } else {
        if (this.bigImgHeightMap[size] > 1000) {
          this.bigImgHeightMap[size] = 1000;
          this.bigImgWidthMap[size] = this.bigImgHeightMap[size] / this.ratio;
        }
        this.smallImgHeightMap[size] = Math.floor(
          this.bigImgHeightMap[size] / size
        );
        const tempSize = this.bigImgHeightMap[size] / size;
        this.smallImgWidthMap[size] = this.smallImgHeightMap[size];
        this.bigImgHeightMap[size] = size * this.smallImgHeightMap[size];
        this.bigImgWidthMap[size] =
          this.smallImgHeightMap[size] *
          Math.round(this.bigImgWidthMap[size] / tempSize);
      }
      this.previewTextImg[size] = `${+(
        this.bigImgWidthMap[size] / this.smallImgWidthMap[size]
      )} x ${+(this.bigImgHeightMap[size] / this.smallImgHeightMap[size])}`;
    });
  }
  async onFileSelected(event) {
    this.allowBigUpload = false;
    this.bigImgStatus = 'uploadTheBig';
    this.bigImgSelected = undefined;
    const blob = await this.resizeBigImage(
      event.target.files[0],
      1000,
      1000
    );
    const file = this.blobToFile(blob, "t");
    this.bigImgSelected = file;
    if (/heic/.test(this.bigImgSelected.type) || !this.bigImgSelected.type) {
      let k = this.bigImgSelected;
      try {
        this.bigImgSelected = await heic2any({ blob: this.bigImgSelected, toType: "image/jpeg", quality: 0.5, })
      }
      catch (e) {
        this.bigImgSelected = k;
      };
    }

    if (this.bigImgSelected) {
      const reader = new FileReader();

      reader.readAsDataURL(this.bigImgSelected);

      reader.onload = (e) => {
        this.bigImgSelectedUrl = e.target.result;
        this.onUpload();
      };
    }
  }

  onUpload() {
    this.allowBigUpload = true;
    this.finalBigImgs = [];
    this.uploadService.uploadBigImage(this.bigImgSelected).subscribe((e) => {
      if (e.type === HttpEventType.UploadProgress) {
        this.bigImgLoadingProgress = (e.loaded / e.total) * 100 + '%';
        this.bigImgStatus = 'loading';
      } else if (e.type === HttpEventType.Response) {
        this.bigImgUploadedUrl = e.body.urls[0];
        this.changeDetectorRef.detectChanges();
        this.bigImgStatus = 'bigImageUploaded';
        localStorage.setItem('bigImgUploadedUrl', this.bigImgUploadedUrl);
        setTimeout(() => {
          this.myStepper.next();
        }, 2000);
        if (!this.authService.userData.isAnonymous) {
          this.uploadService.updateUserData({
            email: this.authService.userData.email,
            width: this.bigImageUploadedWidth || 0,
            height: this.bigImageUploadedHieght || 0,
            urls: this.smallImgsUploadedUrls || [],
            basePhotoUrl: this.bigImgUploadedUrl,
            tokens: this.firestoreUser?.tokens || 0
          }).subscribe(e => {

          });
        }
      }
    });
  }
  async imageCropped(event: ImageCroppedEvent) {
    this.files[this.selectedCropIndex] = this.b64toBlob(
      event.base64.split(',')[1]
    );
    this.imageBase64 = event.base64;
  }
  startCropping(content, index) {
    this.selectedCropIndex = this.files.length - index - 1;
    this.selectedIndex = this.smallImgSelectedUrls.length - index - 1;
    console.log(this.selectedCropIndex);
    console.log(this.selectedIndex);
    const file = this.files[this.selectedCropIndex];
    this.imageChangedEvent.target.files = [this.files[this.selectedCropIndex]];
    this.ngModalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        async (result) => {
          this.editingMode[this.selectedIndex] = true;
          // const blob = await this.resizeImage(
          //   this.files[this.selectedCropIndex],
          //   400,
          //   400
          // );
          const reader = new FileReader();
          reader.readAsDataURL(this.files[this.selectedCropIndex]);
          const selInd = this.selectedIndex;
          reader.onload = (ev) => {
            this.smallImgSelectedUrls[selInd] = ev.target.result;
          };
        },
        async (reason) => {
          // this.editingMode[this.selectedIndex] = true;
          // const blob = await this.resizeImage(
          //   this.files[this.selectedCropIndex],
          //   80,
          //   80
          // );
          // const reader = new FileReader();
          // reader.readAsDataURL(blob);
          // const selInd = this.selectedIndex;
          // reader.onload = (ev) => {
          //   this.smallImgSelectedUrls[selInd] = ev.target.result;
          // };
          this.files[this.selectedCropIndex] = file;
        }
      );
  }
  blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = { ...theBlob, lastModifiedDate: new Date(), name: fileName };
    return <File>theBlob;
  };
  async getFileFromUrl(url, name, defaultType = 'image/jpeg') {
    const response = await fetch(url);
    return <File>(await response.blob());
    // return new File([data], name, {
    // type: data.type || defaultType,
    // });
  }
  async onFilesSelected(e) {
    this.smallImgsPreviewMessage = 'Processing images...';
    this.uploadGoingOn = true;
    let length = e.target.files.length;
    if (e.target.files) {
      let count = 0;
      await Promise.all(
        [...e.target.files].map(async (fil: any) => {
          try {
            let file = fil;
            const type = fil.type;
            console.log("fil: " + fil)
            // if (/heic/.test(type) || !type) {
            //   console.log("this is heic: " + type);
            //   try {
            //     file = await heic2any({ blob: fil, toType: "image/jpeg", quality: 0, })
            //   }
            //   catch (e) {
            //     file = fil;
            //   };
            // }
            const blob = await this.resizeImage(file, 400, 400, true);

            const another = await this.resizeImage(
              this.blobToFile(blob, 'id' + Math.random),
              80,
              80
            );
            const reader = new FileReader();
            reader.readAsDataURL(another);
            reader.onload = (ev) => {
              this.files.push(blob);
              this.editingMode.push(false);
              this.smallImgSelectedUrls.push(ev.target.result);
              this.smallImgsPreviewMessage = '';
              this.smallImgsProgress =
                Math.round(
                  (this.counter / this.smallImgSelectedUrls.length) * 100
                ) + '%';
              count++;

              if (count === length) {
                this.allowSmallUpload = false;
                this.uploadGoingOn = false;
              }
            };
          } catch (e) {
            length--;
            console.log(e);
          }
        })
      );
    }
    else {
      this.smallImgsPreviewMessage = '';
    }
  }
  async executeUploadBatch(files, length) {
    let counter = 0;
    let failedCount = 0;
    await Promise.all(files.map(async file => {
      try {
        this.uploadService.uploadSmallImages(file, this.asset).subscribe(async (e) => {
          if (e.type === HttpEventType.UploadProgress) {
            this.smallImgLoadingProgressMap[this.counter] =
              (e.loaded / e.total) * 100 + '%';
          } else if (e.type === HttpEventType.Response) {
            this.smallImgsUploadedUrls.push(e.body.urls[0]);

            this.finalBigImgsMap[e.body.urls[0]] = file;
            this.smallImgLoadingProgressMap[this.counter] = '100%';
            this.counter++;
            counter++;
            this.smallImgsProgress =
              Math.round(
                (this.counter / this.smallImgSelectedUrls.length) * 100
              ) + '%';
          }
          if (counter - failedCount === files.length) {
            const chunk = this.files.splice(0, length);
            if (chunk.length > 0) {
              return await this.executeUploadBatch(chunk, length);
            }
            else {
              this.smallImgSelectedUrls = this.smallImgsUploadedUrls.map((url) =>
                url.replace('w=100', 'w=' + '500').replace('h=100', 'h=' + '500')
              );
              this.previousImgsLength = this.smallImgSelectedUrls.length;
              this.smallImgsStatus = 'smallImagesUploaded';
              if (!this.authService.userData.isAnonymous) {
                this.uploadService.updateUserData({
                  email: this.authService.userData.email,
                  width: this.bigImageUploadedWidth,
                  height: this.bigImageUploadedHieght,
                  urls: this.smallImgsUploadedUrls,
                  basePhotoUrl: this.bigImgUploadedUrl,
                  tokens: this.firestoreUser?.tokens || 0
                }).subscribe(e => {
                });
              }
              this.uploadGoingOn = false;
              this.allowClear = false;
              this.files = [];
            }
          }
        }, (e) => {
          failedCount++;
          console.log("catched old");
          console.log(e);
        });
      } catch (e) {
        console.log("catched");
        failedCount++;
      }

    }))
  }
  async onUploadSmall() {
    this.allowSmallUpload = true;
    this.uploadGoingOn = true;
    this.allowClear = true;
    this.smallImgsStatus = 'uploadingSmallImages';
    this.finalBigImgs = [];
    const date = new Date();
    const dayOfYear = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    if (!this.asset) {
      this.asset = 'small_' + dayOfYear + '_' + this.email;
    }
    this.counter = this.smallImgsUploadedUrls.length;
    const chunk = this.files.splice(0, 50);
    await this.executeUploadBatch(chunk, 50);
  }

  async onReEnlarge(slid, op) {
    debugger;
    if (op === this.selectedOpacity) return;
    await this.reenlarge(slid, op, this.korisnik !== "marko");
  }
  async reenlarge(slid, op, wmgen) {
    this.selectedOpacity = op;
    this.sliderFilter = slid;
    this.largeImageSrc = "";
    this.largeCanvas.nativeElement.style.display = 'none';
    this.context2.clearRect(0, 0, Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef));
    this.context2.fillStyle = 'white';
    this.context2.fillRect(0, 0, Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef));
    this.context2.globalAlpha = 1;
    this.context2.putImageData(this.mosaicFinalImage, 0, 0);
    this.context2.globalAlpha = this.sliderFilter;
    this.context2.filter = 'blur(' + this.blurCoef + 'px)';
    this.context2.drawImage(this.bimgSaved, 0, 0, Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef));
    this.context2.filter = 'blur(0px)';
    if (wmgen) {
      this.context2.globalAlpha = 0.6;
      const wmW = this.selectedH > this.selectedW ? this.selectedH : this.selectedW;
      this.context2.drawImage(this.wmimgSaved, 0, 0, wmW * this.ecoef, wmW * this.ecoef);
    }
    this.largeImageSrc = this.getLargeImageSrc();
    this.finalDone = true;
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }
  onGenerate(size) {
    this.mosaicGeneratingMsg = "mosaicIsBeingCreated";
    this.finalDone = false;
    this.name = '';
    window.scrollTo(0, 60);
    this.previewInfoText = false;
    this.selectedH = this.bigImgHeightMap[size];
    this.selectedW = this.bigImgWidthMap[size];
    const email = this.authService.userData?.email;
    this.email = email;
    if (this.isIOS) {
      let ij = 2;
      while (ij * ij * this.selectedH * this.selectedW <= 16777200) {
        ij++;
        if (ij * ij * this.selectedH * this.selectedW > 16777200) {
          ij--;
          break;
        }
      }
      this.ecoef = ij;
    }
    else if (this.isMobile) {
      let ija = 2;
      while (ija * ija * this.selectedH * this.selectedW <= 28100000) {
        ija++;
        if (ija * ija * this.selectedH * this.selectedW > 28100000) {
          ija--;
          break;
        }
      }
      this.ecoef = ija;
    }
    this.selectedSmallH = this.smallImgHeightMap[size];
    this.selectedSmallW = this.smallImgWidthMap[size];
    const bigUrl = this.bigImgUploadedUrl
      .replace('w=100', 'w=' + this.bigImgWidthMap[size])
      .replace('h=100', 'h=' + this.bigImgHeightMap[size]);
    const finalId = uuid.v4();
    const body = {
      basePhotoUrl: bigUrl,
      width: +this.bigImgWidthMap[size],
      height: +this.bigImgHeightMap[size],
      smallImageWidth: +this.smallImgWidthMap[size],
      smallImageHeight: +this.smallImgHeightMap[size],
      enlargeCoef: +this.ecoef,
      repeat: +this.repeat,
      filter: 0,
      urls: this.smallImgsUploadedUrls,
      email: this.email,
      finalId,
      atleastone: this.atleastone
    };
    this.loading = true;
    this.uploadService.generate(body).subscribe((e) => {
      if (e.type === HttpEventType.UploadProgress) {
        const generationProgress = (e.loaded / e.total) * 100 + '%';
        console.log(generationProgress);
      } else if (e.type === HttpEventType.Response) {
        console.log("response reveived");
        // this.finalBigImgs = [];
        // this.name = e.body.name;
        // this.previewImg = "https://images.sajtzadan.com/" + e.body.getUrl;
        // this.finalMozaics = e.body.finalMozaics;
        // this.sliderFilter = this.sliderFilter === 0 ? 0.25 : this.sliderFilter;
        // this.onEnlarge();
        // this.changeDetectorRef.detectChanges();
      }
    });
    this.firestore.collection("finals").doc(finalId).snapshotChanges().subscribe(async doc => {
      const data: any = doc.payload.data();
      if (data) {
        let moz = [];
        const name = data.name;
        const mozes = await this.firestore.collection("mozlikes").ref.where("name", "==", name).get();
        mozes.docs.map((d: any) => {
          moz = moz.concat(d.data().array);
        })
        this.finalBigImgs = [];
        this.name = name;
        this.finalMozaics = moz;
        this.sliderFilter = this.sliderFilter === 0 ? 0.25 : this.sliderFilter;
        this.onEnlarge();
        this.changeDetectorRef.detectChanges();
        console.log("Current data: ", moz);
      }
    })
    if (this.viewer !== undefined) {
      this.viewer.destroy();
      this.viewer = null;
    }
  }
  async getLargeImage() {
    debugger;
    this.selectedH = this.bigImgHeightMap[50];
    this.selectedW = this.bigImgWidthMap[50];
    this.selectedSmallH = this.smallImgHeightMap[50];
    this.selectedSmallW = this.smallImgWidthMap[50];
    let moz = [];
    const name = this.chosenName;
    const mozes = await this.firestore.collection("mozlikes").ref.where("name", "==", name).get();
    mozes.docs.map((d: any) => {
      moz = moz.concat(d.data().array);
    })
    console.log(moz);
    this.finalBigImgs = [];
    this.name = name;
    this.finalMozaics = moz;
    this.sliderFilter = this.sliderFilter === 0 ? 0.25 : this.sliderFilter;
    this.onEnlarge();
    this.changeDetectorRef.detectChanges();
  }
  reloadSmall() {
    this.img = document.getElementById('img') as HTMLImageElement;

    this.img.src = this.previewImg;
  }
  backToCanvas() {
    this.paypalScreen = false;
    this.changeDetectorRef.detectChanges();
    const element = document.getElementById('panzoomCanvas');

    this.panz = panzoom(element, {
      bounds: false,
      maxZoom: 10,
      minZoom: 0.25
    })
  }
  loadPreview() {
    this.loading = false;
    this.changeDetectorRef.detectChanges();
    this.img = document.getElementById('img') as HTMLImageElement;
    this.img.style.visibility = 'hidden';
    this.img.style.display = 'none';

    this.myCanvas.nativeElement.height = this.selectedH;
    this.myCanvas.nativeElement.width = this.selectedW;

    this.context = this.myCanvas.nativeElement.getContext('2d');
    this.context.drawImage(this.img, 0, 0);

    this.modify();
  }
  onReload() {
    this.img.src = this.previewImg;
    this.img.style.visibility = 'visible';
    this.img.style.display = 'block';

    this.loadPreview();
  }
  onJsonChange(event) {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], "UTF-8");
    fileReader.onload = (ev) => {
      const json = JSON.parse(ev.target.result.toString());
      const base = json.url;
      const urls = json.urls;
      const w = parseInt(base.split("?")[1].split("h=")[0].replace("w=", "").replace("&", ""));
      const h = parseInt(base.split("?")[1].split("h=")[1]);
      if (!this.authService.userData.isAnonymous) {
        this.uploadService.updateUserData({
          email: this.authService.userData.email,
          width: w,
          height: h,
          urls,
          basePhotoUrl: base,
          tokens: this.firestoreUser?.tokens || 0
        }).subscribe(e => {
        });
      }
    }
    fileReader.onerror = (error) => {
      console.log(error);
    }
  }
  applySample(fileName) {
    this.smallImgsStatus = 'smallImagesUploaded';
    const sampleUrls = getSample(fileName);
    this.smallImgsUploadedUrls = this.smallImgsUploadedUrls.concat(sampleUrls);
    this.smallImgSelectedUrls = this.smallImgSelectedUrls.concat(sampleUrls);
    this.counter += sampleUrls.length;
    // localStorage.setItem(
    //   'smallUploadededUrls',
    //   JSON.stringify(this.smallImgsUploadedUrls)
    // );
    if (!this.authService.userData.isAnonymous) {
      this.uploadService.updateUserData({
        email: this.authService.userData.email,
        width: this.bigImageUploadedWidth,
        height: this.bigImageUploadedHieght,
        urls: this.smallImgsUploadedUrls,
        basePhotoUrl: this.bigImgUploadedUrl,
        tokens: this.firestoreUser?.tokens || 0
      }).subscribe(e => {
      });
    }
    localStorage.setItem(
      'sample_' + fileName + '_Applied',
      "true"
    );
    this.enableUrbanArtBtn = false;
    this.changeDetectorRef.detectChanges();
  }

  onEnlarge() {
    window.scrollTo(0, 60);
    this.mosaicGeneratingMsg = 'almostDone';
    this.largeImageSrc = "";
    this.wmimgSaved = "";
    this.bimgSaved = "";
    this.mosaicFinalImage = null;
    this.previewInfoText = false;
    this.final = '';
    this.largeCanvas.nativeElement.height = Math.floor(this.selectedH * this.ecoef);
    this.largeCanvas.nativeElement.width = Math.floor(this.selectedW * this.ecoef);
    this.context2 = this.largeCanvas.nativeElement.getContext('2d');
    this.changeDetectorRef.detectChanges();
    this.drawLarge(this.korisnik !== "marko");

  }
  drawLarge(withWatermark) {
    this.finalDone = false;
    this.bigLoaded = false;
    this.wmLoaded = !withWatermark;
    this.largeCanvas.nativeElement.style.display = 'none';
    this.loading = true;

    this.context2.clearRect(0, 0, Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef));
    this.context2.fillStyle = 'white';
    this.context2.fillRect(0, 0, Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef));
    this.context2.globalAlpha = 1;
    this.l = 0;
    if (this.finalBigImgs.length === 0) {
      for (let i = 0; i < this.finalMozaics.length; i++) {
        const imgObj = {
          img: new Image(),
          rgb: this.finalMozaics[i].rgb,
          x: this.finalMozaics[i].x,
          y: this.finalMozaics[i].y,
          color: this.finalMozaics[i].color,
        };
        imgObj.img.crossOrigin = 'anonymous';
        if (this.finalBigImgsMap[this.finalMozaics[i].url] !== undefined) {
          this.context2.globalAlpha = 1;
          this.context2.drawImage(
            this.finalBigImgsMap[this.finalMozaics[i].url].img,
            Math.floor(imgObj.x * this.ecoef),
            Math.floor(imgObj.y * this.ecoef),
            Math.floor(this.selectedSmallW * this.ecoef),
            Math.floor(this.selectedSmallW * this.ecoef)
          );
          if (this.includeFilters) {
            this.context2.globalAlpha = 0.05;
            this.context2.fillStyle = "rgb(" + this.finalMozaics[i].rgb.r + "," + this.finalMozaics[i].rgb.g + "," + this.finalMozaics[i].rgb.r + ")";
            this.context2.globalCompositeOperation = 'hue';
            this.context2.fillRect(Math.floor(imgObj.x * this.ecoef),
              Math.floor(imgObj.y * this.ecoef),
              Math.floor(this.selectedSmallW * this.ecoef),
              Math.floor(this.selectedSmallW * this.ecoef))
            this.context2.globalAlpha = 1;
            this.context2.fillStyle = "transparent";
            this.context2.globalCompositeOperation = "source-over";
          }
          this.l++;
          this.finalBigImgs.push(imgObj);
          this.changeDetectorRef.detectChanges();
          if (this.l === this.finalMozaics.length) {
            this.mosaicFinalImage = this.context2.getImageData(0, 0, Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef));
            this.context2.globalAlpha = 0.85;
            let bimg = new Image();
            if (this.bimgSaved) {
              if (withWatermark) {
                if (this.wmimgSaved) {
                  this.context2.globalAlpha = 0.6;
                  const wmW = this.selectedH > this.selectedW ? this.selectedH : this.selectedW;
                  this.context2.drawImage(
                    this.wmimgSaved,
                    0,
                    0,
                    Math.floor(wmW * this.ecoef), Math.floor(wmW * this.ecoef)
                  );
                  this.wmLoaded = true;
                  if (this.bigLoaded) {
                    this.finalDone = true;
                    this.largeImageSrc = this.getLargeImageSrc();
                    this.loading = false;
                    this.changeDetectorRef.detectChanges()
                    const element = document.getElementById('panzoomCanvas');
                    this.panz = panzoom(element, {
                      bounds: false,
                      maxZoom: 10,
                      minZoom: 0.25
                    })
                  }
                }
                else {
                  let watermarkImage = new Image();
                  watermarkImage.onload = () => {

                    this.context2.globalAlpha = 0.6;
                    const wmW = this.selectedH > this.selectedW ? this.selectedH : this.selectedW;
                    this.context2.drawImage(
                      watermarkImage,
                      0,
                      0,
                      Math.floor(wmW * this.ecoef), Math.floor(wmW * this.ecoef)
                    );
                    this.wmLoaded = true;
                    this.wmimgSaved = watermarkImage;
                    if (this.bigLoaded) {
                      this.finalDone = true;
                      this.largeImageSrc = this.getLargeImageSrc();
                      this.loading = false;
                      this.changeDetectorRef.detectChanges()
                      const element = document.getElementById('panzoomCanvas');
                      this.panz = panzoom(element, {
                        bounds: false,
                        maxZoom: 10,
                        minZoom: 0.25
                      })
                    }
                  };
                  watermarkImage.src = "https://static.sajtzadan.com/wm1a.png";
                  watermarkImage.crossOrigin = "anonymous";
                }
              }
              this.context2.globalAlpha = this.sliderFilter;
              this.context2.filter = 'blur(' + this.blurCoef + 'px)';
              this.context2.drawImage(
                this.bimgSaved,
                0,
                0,
                Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef)
              );
              this.bigLoaded = true;
              this.context2.filter = 'blur(0px)';
              if (!withWatermark || this.wmLoaded) {
                this.finalDone = true;
                this.largeImageSrc = this.getLargeImageSrc();
                this.loading = false;
                this.changeDetectorRef.detectChanges()
                const element = document.getElementById('panzoomCanvas');
                this.panz = panzoom(element, {
                  bounds: false,
                  maxZoom: 10,
                  minZoom: 0.25
                })
              }
            }
            else {
              bimg.onload = () => {
                if (withWatermark) {
                  if (this.wmimgSaved) {
                    this.context2.globalAlpha = 0.6;
                    const wmW = this.selectedH > this.selectedW ? this.selectedH : this.selectedW;
                    this.context2.drawImage(
                      this.wmimgSaved,
                      0,
                      0,
                      Math.floor(wmW * this.ecoef), Math.floor(wmW * this.ecoef)
                    );
                    this.wmLoaded = true;
                    if (this.bigLoaded) {
                      this.finalDone = true;
                      this.largeImageSrc = this.getLargeImageSrc();
                      this.loading = false;
                      this.changeDetectorRef.detectChanges()
                      const element = document.getElementById('panzoomCanvas');
                      this.panz = panzoom(element, {
                        bounds: false,
                        maxZoom: 10,
                        minZoom: 0.25
                      })
                    }
                  }
                  else {
                    let watermarkImage = new Image();
                    watermarkImage.onload = () => {

                      this.context2.globalAlpha = 0.6;
                      const wmW = this.selectedH > this.selectedW ? this.selectedH : this.selectedW;
                      this.context2.drawImage(
                        watermarkImage,
                        0,
                        0,
                        Math.floor(wmW * this.ecoef), Math.floor(wmW * this.ecoef)
                      );
                      this.wmLoaded = true;
                      this.wmimgSaved = watermarkImage;
                      if (this.bigLoaded) {
                        this.finalDone = true;
                        this.largeImageSrc = this.getLargeImageSrc();
                        this.loading = false;
                        this.changeDetectorRef.detectChanges()
                        const element = document.getElementById('panzoomCanvas');
                        this.panz = panzoom(element, {
                          bounds: false,
                          maxZoom: 10,
                          minZoom: 0.25
                        })
                      }
                    };
                    watermarkImage.src = "https://static.sajtzadan.com/wm1a.png";
                    watermarkImage.crossOrigin = "anonymous";
                  }
                }
                this.context2.globalAlpha = this.sliderFilter;
                this.context2.filter = 'blur(' + this.blurCoef + 'px)';
                this.context2.drawImage(
                  bimg,
                  0,
                  0,
                  Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef)
                );
                this.bigLoaded = true;
                this.bimgSaved = bimg;
                this.context2.filter = 'blur(0px)';
                if (!withWatermark || this.wmLoaded) {
                  this.finalDone = true;
                  this.largeImageSrc = this.getLargeImageSrc();
                  this.loading = false;
                  this.changeDetectorRef.detectChanges()
                  const element = document.getElementById('panzoomCanvas');
                  this.panz = panzoom(element, {
                    bounds: false,
                    maxZoom: 10,
                    minZoom: 0.25
                  })
                }
              };
              bimg.src = this.bigImgUploadedUrl.replace("?w=100&h=100", "");
              bimg.crossOrigin = "anonymous";
            }
          }
        } else {
          this.loadTheSmallImage(imgObj, withWatermark, i);
        }
      }
    } else {
      for (let i = 0; i < this.finalBigImgs.length; i++) {
        this.context2.globalAlpha = 1;
        this.context2.drawImage(
          this.finalBigImgs[i].img,
          Math.floor(this.finalBigImgs[i].x * this.ecoef),
          Math.floor(this.finalBigImgs[i].y * this.ecoef),
          Math.floor(this.selectedSmallW * this.ecoef),
          Math.floor(this.selectedSmallW * this.ecoef)
        );
        if (this.includeFilters) {
          this.context2.globalAlpha = 0.05;
          this.context2.fillStyle = "rgb(" + this.finalBigImgs[i].rgb.r + "," + this.finalBigImgs[i].rgb.g + "," + this.finalBigImgs[i].rgb.r + ")";
          this.context2.globalCompositeOperation = 'hue';
          this.context2.fillRect(Math.floor(this.finalBigImgs[i].x * this.ecoef),
            Math.floor(this.finalBigImgs[i].y * this.ecoef),
            Math.floor(this.selectedSmallW * this.ecoef),
            Math.floor(this.selectedSmallW * this.ecoef))
          this.context2.globalAlpha = 1;
          this.context2.fillStyle = "transparent";
          this.context2.globalCompositeOperation = "source-over";
        }
        this.l++;
        this.changeDetectorRef.detectChanges();
        if (this.l === this.finalMozaics.length) {
          this.mosaicFinalImage = this.context2.getImageData(0, 0, Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef));
          let bimg = new Image();

          if (this.bimgSaved) {
            if (withWatermark) {
              if (this.wmimgSaved) {
                this.context2.globalAlpha = 0.6;
                const wmW = this.selectedH > this.selectedW ? this.selectedH : this.selectedW;
                this.context2.drawImage(
                  this.wmimgSaved,
                  0,
                  0,
                  Math.floor(wmW * this.ecoef), Math.floor(wmW * this.ecoef)
                );
                this.wmLoaded = true;
                if (this.bigLoaded) {
                  this.finalDone = true;
                  this.largeImageSrc = this.getLargeImageSrc();
                  this.loading = false;
                  this.changeDetectorRef.detectChanges()
                  const element = document.getElementById('panzoomCanvas');
                  this.panz = panzoom(element, {
                    bounds: false,
                    maxZoom: 10,
                    minZoom: 0.25
                  })
                }
              }
              else {
                let watermarkImage = new Image();
                watermarkImage.onload = () => {
                  this.context2.globalAlpha = 0.6;
                  const wmW = this.selectedH > this.selectedW ? this.selectedH : this.selectedW;
                  this.context2.drawImage(
                    watermarkImage,
                    0,
                    0,
                    Math.floor(wmW * this.ecoef), Math.floor(wmW * this.ecoef)
                  );
                  this.wmLoaded = true;
                  this.wmimgSaved = watermarkImage;
                  if (this.bigLoaded) {
                    this.finalDone = true;
                    this.largeImageSrc = this.getLargeImageSrc();
                    this.loading = false;
                    this.changeDetectorRef.detectChanges()
                    const element = document.getElementById('panzoomCanvas');
                    this.panz = panzoom(element, {
                      bounds: false,
                      maxZoom: 10,
                      minZoom: 0.25
                    })
                  }
                };

                watermarkImage.src = "https://static.sajtzadan.com/wm1a.png";

                watermarkImage.crossOrigin = "anonymous";
              }
            }
            this.context2.globalAlpha = this.sliderFilter;
            this.context2.filter = 'blur(' + this.blurCoef + 'px)';
            this.context2.drawImage(
              this.bimgSaved,
              0,
              0,
              Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef)
            );
            this.bigLoaded = true;
            this.context2.filter = 'blur(0px)';
            if (!withWatermark || this.wmLoaded) {
              this.finalDone = true;
              this.largeImageSrc = this.getLargeImageSrc();
              this.loading = false;
              this.changeDetectorRef.detectChanges()
              const element = document.getElementById('panzoomCanvas');
              this.panz = panzoom(element, {
                bounds: false,
                maxZoom: 10,
                minZoom: 0.25
              })
            }
          }
          else {
            bimg.onload = () => {
              if (withWatermark) {
                if (this.wmimgSaved) {
                  this.context2.globalAlpha = 0.6;
                  const wmW = this.selectedH > this.selectedW ? this.selectedH : this.selectedW;
                  this.context2.drawImage(
                    this.wmimgSaved,
                    0,
                    0,
                    Math.floor(wmW * this.ecoef), Math.floor(wmW * this.ecoef)
                  );
                  this.wmLoaded = true;
                  if (this.bigLoaded) {
                    this.finalDone = true;
                    this.largeImageSrc = this.getLargeImageSrc();
                    this.loading = false;
                    this.changeDetectorRef.detectChanges()
                    const element = document.getElementById('panzoomCanvas');
                    this.panz = panzoom(element, {
                      bounds: false,
                      maxZoom: 10,
                      minZoom: 0.25
                    })
                  }
                }
                else {
                  let watermarkImage = new Image();
                  watermarkImage.onload = () => {

                    this.context2.globalAlpha = 0.6;
                    const wmW = this.selectedH > this.selectedW ? this.selectedH : this.selectedW;
                    this.context2.drawImage(
                      watermarkImage,
                      0,
                      0,
                      Math.floor(wmW * this.ecoef), Math.floor(wmW * this.ecoef)
                    );
                    this.wmLoaded = true;
                    this.wmimgSaved = watermarkImage;
                    if (this.bigLoaded) {
                      this.finalDone = true;
                      this.largeImageSrc = this.getLargeImageSrc();
                      this.loading = false;
                      this.changeDetectorRef.detectChanges()
                      const element = document.getElementById('panzoomCanvas');
                      this.panz = panzoom(element, {
                        bounds: false,
                        maxZoom: 10,
                        minZoom: 0.25
                      })
                    }
                  };

                  watermarkImage.src = "https://static.sajtzadan.com/wm1a.png";

                  watermarkImage.crossOrigin = "anonymous";
                }
              }
              this.context2.globalAlpha = this.sliderFilter;
              this.context2.filter = 'blur(' + this.blurCoef + 'px)';
              this.context2.drawImage(
                bimg,
                0,
                0,
                Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef)
              );
              this.bigLoaded = true;
              this.bimgSaved = bimg;
              this.context2.filter = 'blur(0px)';
              if (!withWatermark || this.wmLoaded) {
                this.finalDone = true;
                this.largeImageSrc = this.getLargeImageSrc();
                this.loading = false;
                this.changeDetectorRef.detectChanges()
                const element = document.getElementById('panzoomCanvas');
                this.panz = panzoom(element, {
                  bounds: false,
                  maxZoom: 10,
                  minZoom: 0.25
                })
              }
            };
            bimg.src = this.bigImgUploadedUrl.replace("?w=100&h=100", "");
            bimg.crossOrigin = "anonymous";
          }

        }
      }
    }
  }
  loadTheSmallImage(imgObj, withWatermark, i) {
    imgObj.img.onload = () => {
      this.context2.globalAlpha = 1;
      this.context2.drawImage(imgObj.img, Math.floor(imgObj.x * this.ecoef), Math.floor(imgObj.y * this.ecoef), Math.floor(this.selectedSmallW * this.ecoef), Math.floor(this.selectedSmallW * this.ecoef)); // Or at whatever offset you like
      if (this.includeFilters) {
        this.context2.globalAlpha = 0.05;
        this.context2.fillStyle = "rgb(" + imgObj.rgb.r + "," + imgObj.rgb.g + "," + imgObj.rgb.r + ")";
        this.context2.globalCompositeOperation = 'hue';
        this.context2.fillRect(Math.floor(imgObj.x * this.ecoef),
          Math.floor(imgObj.y * this.ecoef),
          Math.floor(this.selectedSmallW * this.ecoef),
          Math.floor(this.selectedSmallW * this.ecoef))
        this.context2.globalAlpha = 1;
        this.context2.fillStyle = "transparent";
        this.context2.globalCompositeOperation = "source-over";
      }

      this.finalBigImgs.push(imgObj);
      this.l++;
      this.changeDetectorRef.detectChanges();
      if (this.l === this.finalMozaics.length) {
        this.mosaicFinalImage = this.context2.getImageData(0, 0, Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef));
        let bimg = new Image();
        if (this.bimgSaved) {
          if (withWatermark) {
            if (this.wmimgSaved) {
              this.context2.globalAlpha = 0.6;
              const wmW = this.selectedH > this.selectedW ? this.selectedH : this.selectedW;
              this.context2.drawImage(
                this.wmimgSaved,
                0,
                0,
                Math.floor(wmW * this.ecoef), Math.floor(wmW * this.ecoef)
              );
              this.wmLoaded = true;
              if (this.bigLoaded) {
                this.finalDone = true;
                this.largeImageSrc = this.getLargeImageSrc();
                this.loading = false;
                this.changeDetectorRef.detectChanges()
                const element = document.getElementById('panzoomCanvas');
                this.panz = panzoom(element, {
                  bounds: false,
                  maxZoom: 10,
                  minZoom: 0.25
                })
              }
            }
            else {
              let watermarkImage = new Image();
              watermarkImage.onload = () => {
                this.context2.globalAlpha = 0.6;
                const wmW = this.selectedH > this.selectedW ? this.selectedH : this.selectedW;
                this.context2.drawImage(
                  watermarkImage,
                  0,
                  0,
                  Math.floor(wmW * this.ecoef), Math.floor(wmW * this.ecoef)
                );
                this.wmLoaded = true;
                this.wmimgSaved = watermarkImage;
                if (this.bigLoaded) {
                  this.finalDone = true;
                  this.largeImageSrc = this.getLargeImageSrc();
                  this.loading = false;
                  this.changeDetectorRef.detectChanges()
                  const element = document.getElementById('panzoomCanvas');
                  this.panz = panzoom(element, {
                    bounds: false,
                    maxZoom: 10,
                    minZoom: 0.25
                  })
                }
              };

              watermarkImage.src = "https://static.sajtzadan.com/wm1a.png";

              watermarkImage.crossOrigin = "anonymous";
            }
          }
          this.context2.globalAlpha = this.sliderFilter;
          this.context2.filter = 'blur(' + this.blurCoef + 'px)';
          this.context2.drawImage(
            this.bimgSaved,
            0,
            0,
            Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef)
          );
          this.bigLoaded = true;
          this.context2.filter = 'blur(0px)';
          if (!withWatermark || this.wmLoaded) {
            this.finalDone = true;
            this.largeImageSrc = this.getLargeImageSrc();
            this.loading = false;
            this.changeDetectorRef.detectChanges()
            const element = document.getElementById('panzoomCanvas');
            this.panz = panzoom(element, {
              bounds: false,
              maxZoom: 10,
              minZoom: 0.25
            })
          }
        }
        else {
          bimg.onload = () => {
            if (withWatermark) {
              if (this.wmimgSaved) {
                this.context2.globalAlpha = 0.6;
                const wmW = this.selectedH > this.selectedW ? this.selectedH : this.selectedW;
                this.context2.drawImage(
                  this.wmimgSaved,
                  0,
                  0,
                  Math.floor(wmW * this.ecoef), Math.floor(wmW * this.ecoef)
                );
                this.wmLoaded = true;
                if (this.bigLoaded) {
                  this.finalDone = true;
                  this.largeImageSrc = this.getLargeImageSrc();
                  this.loading = false;
                  this.changeDetectorRef.detectChanges()
                  const element = document.getElementById('panzoomCanvas');
                  this.panz = panzoom(element, {
                    bounds: false,
                    maxZoom: 10,
                    minZoom: 0.25
                  })
                }
              }
              else {
                let watermarkImage = new Image();
                watermarkImage.onload = () => {

                  this.context2.globalAlpha = 0.6;
                  const wmW = this.selectedH > this.selectedW ? this.selectedH : this.selectedW;
                  this.context2.drawImage(
                    watermarkImage,
                    0,
                    0,
                    Math.floor(wmW * this.ecoef), Math.floor(wmW * this.ecoef)
                  );
                  this.wmLoaded = true;
                  this.wmimgSaved = watermarkImage;
                  if (this.bigLoaded) {
                    this.finalDone = true;
                    this.largeImageSrc = this.getLargeImageSrc();
                    this.loading = false;
                    this.changeDetectorRef.detectChanges()
                    const element = document.getElementById('panzoomCanvas');
                    this.panz = panzoom(element, {
                      bounds: false,
                      maxZoom: 10,
                      minZoom: 0.25
                    })
                  }
                };

                watermarkImage.src = "https://static.sajtzadan.com/wm1a.png";

                watermarkImage.crossOrigin = "anonymous";
              }
            }
            this.context2.globalAlpha = this.sliderFilter;
            this.context2.filter = 'blur(' + this.blurCoef + 'px)';
            this.context2.drawImage(
              bimg,
              0,
              0,
              Math.floor(this.selectedW * this.ecoef), Math.floor(this.selectedH * this.ecoef)
            );
            this.bigLoaded = true;
            this.bimgSaved = bimg;
            this.context2.filter = 'blur(0px)';
            if (!withWatermark || this.wmLoaded) {
              this.finalDone = true;
              this.largeImageSrc = this.getLargeImageSrc();
              this.loading = false;
              this.changeDetectorRef.detectChanges()
              const element = document.getElementById('panzoomCanvas');
              this.panz = panzoom(element, {
                bounds: false,
                maxZoom: 10,
                minZoom: 0.25
              })
            }
          };
          bimg.src = this.bigImgUploadedUrl.replace("?w=100&h=100", "");
          bimg.crossOrigin = "anonymous";
        }
      }
    };
    imgObj.img.onerror = () => {
      this.loadTheSmallImage(imgObj, withWatermark, i);
    }
    imgObj.img.src = this.finalMozaics[i].url
      .replace(
        'w=' + this.selectedSmallW,
        'w=300'
      )
      .replace(
        'h=' + this.selectedSmallH,
        'h=300'
      );
  }
  drawTextBG(ctx, text, font, cw, ch, coef) {
    ctx.font = font;
    var textWidth = ctx.measureText(text).width;
    ctx.globalAlpha = 0.95;
    ctx.fillStyle = 'black';
    ctx.fillText(text, cw - textWidth - coef, ch - coef * 2);
    ctx.fillStyle = 'white';
    ctx.fillText(
      text,
      cw - textWidth - coef + coef / 5,
      ch - coef * 2 + coef / 5
    );
  }

  onReloadOSD() {
    if (this.viewer !== null) {
      this.viewer.destroy();
      this.viewer = null;
    }
    this.viewer = new OpenSeadragon({
      id: 'openseadragon1',
      prefixUrl: '../../assets/img/',
      defaultZoomLevel: 0,
      minZoomLevel: 0,
      visibilityRatio: 1,
      useCanvas: !this.isIOS,
      ensureVisible: true,
      tileSources: {
        type: 'image',
        url: this.final,
      },
    });
  }

  modify(s?) {
    const modify2 = s;
    this.context.clearRect(0, 0, this.selectedW, this.selectedH);
    this.context.drawImage(this.img, 0, 0);
    this.context.globalAlpha = this.sliderFilter;
    if (modify2) {
      for (let i = 0; i < this.finalMozaics.length; i++) {
        this.context.fillStyle = "#" + 'rgb(' + this.finalMozaics[i].avg.r + ',' + this.finalMozaics[i].avg.g + ',' + this.finalMozaics[i].avg.b + ')';
        this.context.fillRect(this.finalMozaics[i].x, this.finalMozaics[i].y, this.selectedW, this.selectedH);
      }
      this.context.globalAlpha = 1;
    }
    else {
      this.context.globalAlpha = this.sliderFilter;

      let simg = new Image();

      simg.onload = () => {

        this.context.drawImage(simg, 0, 0, this.context.canvas.width, this.context.canvas.height);
        this.context.globalAlpha = 1;
        this.drawTextBG(
          this.context,
          'MOZLIKE',
          this.selectedSmallH * 2 + 'px verdana',
          this.selectedW,
          this.selectedH,
          10
        );
      };
      simg.src = this.bigImgUploadedUrl.replace("?w=100&h=100", "");
      simg.crossOrigin = "anonymous";
    }

  }

  clearLocalSorage() {
    localStorage.removeItem('sample_urbanArt_Applied');
    this.instagramAccessToken = null;
    this.enableUrbanArtBtn = true;
    localStorage.setItem("instagramPhotosUploaded", "false");
    localStorage.removeItem('smallUploadededUrls');
    localStorage.removeItem('instagram_access_token');
    this.smallImgsStatus = 'noImagesYet';
    this.smallImgSelectedUrls = [];
    this.smallImgsUploadedUrls = [];
    this.imageChangedEvent = {
      target: {
        files: [],
      },
    };
    this.files = [];
    this.counter = 0;
    this.previousImgsLength = 0;
    if (!this.authService.userData.isAnonymous) {
      this.uploadService.updateUserData({
        email: this.authService.userData?.email,
        width: this.bigImageUploadedWidth || 0,
        height: this.bigImageUploadedHieght || 0,
        urls: [],
        basePhotoUrl: this.bigImgUploadedUrl || "",
        tokens: this.firestoreUser?.tokens || 0
      }).subscribe(e => {
      })
    }
    this.changeDetectorRef.detectChanges();
  }

  removeSmallImg(n) {
    if (n < this.previousImgsLength) {
      this.previousImgsLength--;
    }
    this.smallImgSelectedUrls.splice(n, 1);
    this.editingMode.splice(n, 1);
    this.files.splice(n - this.previousImgsLength, 1);
    this.smallImgsUploadedUrls.splice(n, 1);
    this.counter--;
    if (this.counter < 0) {
      this.counter = 0;
    }
    if (this.smallImgsUploadedUrls.length === 0) {
      this.smallImgsStatus = 'noImagesYet';
    }
  }
  resizeImage(file: File, maxWidth: number, maxHeight: number, setSmall?: boolean): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const image = new Image();


      image.onload = () => {
        const width = image.width;
        const height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let newWidth;
        let newHeight;
        let maxWidthTemp = maxWidth;
        let maxHeightTemp = maxHeight;
        if (width < maxWidth) {
          maxWidthTemp = width;
        }
        if (height < maxHeight) {
          maxHeightTemp = height;
        }
        if (width <= height) {
          newHeight = height * (maxWidthTemp / width);
          newWidth = maxWidthTemp;
        } else {
          newWidth = width * (maxHeightTemp / height);
          newHeight = maxHeightTemp;
        }

        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        const context = canvas.getContext('2d');
        // context.imageSmoothingEnabled = true;

        context.drawImage(image, 0, 0, newWidth, newHeight);
        canvas.toBlob(resolve, file.type, 1);
      };
      image.onerror = reject;
      image.src = URL.createObjectURL(file);
    });

  }
  resizeBigImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const width = image.width;
        const height = image.height;

        let newWidth;
        let newHeight;
        let maxWidthTemp = maxWidth;
        let maxHeightTemp = maxHeight;
        // if (width < maxWidth) {
        //   maxWidthTemp = width;
        // }
        // if (height < maxHeight) {
        //   maxHeightTemp = height;
        // }
        if (width > height) {
          newHeight = height * (maxWidthTemp / width);
          newWidth = maxWidthTemp;
        } else {
          newWidth = width * (maxHeightTemp / height);
          newHeight = maxHeightTemp;
        }

        const canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;
        this.bigImageUploadedWidth = parseInt(newWidth);
        this.bigImageUploadedHieght = parseInt(newHeight);
        this.changeDetectorRef.detectChanges();
        const context = canvas.getContext('2d');

        context.drawImage(image, 0, 0, newWidth, newHeight);
        canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }
  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}

