import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-description',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {
  msg;
  map = {
    "1": "msgConfirm",
    "2": "msgAlreadyConfirmed",
    "3": "msgNotFound"
  }
  constructor() { }

  ngOnInit(): void {
    const params = new URLSearchParams(window.location.search)
    this.msg = this.map[params.get('type') || "3"];

    document.getElementsByTagName('nav')[0].style.backgroundColor = '#3f51b5';
  }
}
