import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(translate: TranslateService) {
    const lang = localStorage.getItem("language");
    if (lang) {
      translate.use(lang);
    }
    else {
      translate.use('en');
    }
    translate.setDefaultLang('en');
  }
  title = 'Mozlike';
}
