import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public isCollapsed = true;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  @ViewChild('selectComp') select: ElementRef;
  @Output() public sidenavToggle = new EventEmitter();

  languages: any[];
  selectedValue: string;
  selected: any;
  constructor(public location: Location, public router: Router, private translateService: TranslateService, private change: ChangeDetectorRef, public authService: AuthService) {
    this.languages = [{ name: 'English', code: 'en', flag: '../../../../assets/img/english.png' }, { name: 'Serbian', code: 'sr', flag: '../../../../assets/img/serbia.png' }]
    this.selectedValue = translateService.currentLang;
    this.selected = this.languages.find(l => l.code === this.selectedValue);
    console.log(this.selectedValue);
  }
  compareColors(p1, p2): boolean {
    if (p1 && p2) {
      return p1 === p2;
    }
    return false;
  }
  changeLang() {
    console.log(this.selectedValue);
    this.translateService.use(this.selectedValue);
    this.selected = this.languages.find(l => l.code === this.selectedValue);
    localStorage.setItem("language", this.selectedValue);
    this.change.detectChanges()
  };

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else
          window.scrollTo(0, 0);
      }
    });
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
  }

  onToggleSideNav() {
    this.sidenavToggle.emit();
  }

}
