import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TogetherService } from '../services/together.service';
import panzoom from 'panzoom';
import { StepperOrientation, StepperSelectionEvent } from '@angular/cdk/stepper';
import { Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { UploadService } from '../services/upload.service';
import { HttpEventType } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
    selector: 'app-share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.css'],
})

export class ShareComponent implements OnInit {
    uploadGoingOn = false;
    firestoreUser = { tokens: 0 };
    atleastone = false;
    isAnonymous = true;
    instagramAccessToken;
    instagramPhotosUploaded;
    largeImageSrc = "";
    mosaicFinalImage;
    paypalScreen = false;
    enableUrbanArtBtn = true;
    finalScreen = false;
    stepperOrientation: Observable<StepperOrientation>;
    isLinear = false;
    bimgSaved;
    wmimgSaved;
    selectedOpacity = 1;
    mosaicGeneratingMsg = 'mosaicIsBeingCreated';
    korisnik;
    imEvent;
    imageBase64;
    bigImgSelected;
    selectedCropIndex;
    selectedIndex;
    img;
    dataUrl;
    bigImgSelectedUrl;
    bigImgWidthMap = {};
    bigImgHeightMap = {};
    bigImgUploadedUrl;
    bigImgLoadingProgress;
    bigImgStatus = 'uploadTheBig';
    sliderFilter = 0;
    finalDone = false;
    wmLoaded;
    bigLoaded;
    finalBigImgs = [];
    finalBigImgsMap = {};
    bigImageUploadedHieght;
    bigImageUploadedWidth;
    allowBigUpload = true;
    previousImgsLength = 0;
    editingMode = [];
    cardWH;
    blurCoef = 18;
    maxRepeat = 0;
    minRepeat = 0;
    asset;
    panz;
    files = [];
    smallImgLoadingProgressMap = {};
    smallImgSelectedUrls = [];
    smallImgsUploadedUrls = [];
    smallImgsStatus = 'noImagesYet';
    smallImgsProgress;
    counter = 0;
    finalMozaics: any = [];
    previewInfoText = false;
    allowSmallUpload = false;
    allowClear = false;
    smallImgWidthMap = {};
    droppedFiles = [];
    smallImgHeightMap = {};
    ecoef = 15;
    filter = 0;
    repeat = 1000000;
    repeatChecked = false;
    previewImg;
    previewTextImg = {};
    smallImgsPreviewMessage;
    name;
    paypal;
    chosenName;
    final;
    viewer;
    email;
    imgSrc = "https://images.sajtzadan.com/https://static.sajtzadan.com/mozlike/deskbird2.jpeg";
    isFinished = false;
    isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
            navigator.userAgent
        );
    @ViewChild('largeCanvas')
    largeCanvas: ElementRef<HTMLCanvasElement>;
    @ViewChild('myIframe')
    myIframe: ElementRef<HTMLIFrameElement>;
    public context2: CanvasRenderingContext2D;
    imagesData: { link, name, loaded, preview }[] = [];
    z;
    imageChangedEvent: any = {
        target: {
            files: [],
        },
    };
    offset = 0;
    link;
    selectedW;
    ratio;
    selectedH;
    l = 0;
    id;
    selectedSmallW;
    selectedSmallH;
    done = false;
    constructor(private ngModalService: NgbModal, private uploadService: UploadService, breakpointObserver: BreakpointObserver, private route: ActivatedRoute, private togetherService: TogetherService, private changeDetectorRef: ChangeDetectorRef) {

        this.stepperOrientation = breakpointObserver
            .observe('(min-width: 800px)')
            .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    }
    ngAfterViewInit(): void {

    }
    createTogether(size) {
        const bigUrl = this.bigImgUploadedUrl
            .replace('w=100', 'w=' + this.bigImgWidthMap[size])
            .replace('h=100', 'h=' + this.bigImgHeightMap[size]);
        const cols = +(this.bigImgWidthMap[size] / this.smallImgWidthMap[size]);
        const rows = +(this.bigImgHeightMap[size] / this.smallImgHeightMap[size]);
        this.togetherService.createTogether({
            url: bigUrl,
            width: this.bigImgWidthMap[size],
            height: this.bigImgHeightMap[size],
            rows,
            cols
        }).subscribe((e) => {
            if (e.type === HttpEventType.Response) {
                console.log(e);
                this.link = "https://web.mozlike.com/together?id=" + e.body.id
            }
        });
    }
    copyTheLink() {
        console.log("link: " + this.link);
    }
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
    getRatio(x, y) {
        if (x > y) {
            return Math.round((x / y + Number.EPSILON) * 100) / 100;
        } else if (y > x) {
            return Math.round((y / x + Number.EPSILON) * 100) / 100;
        } else return 1;
    }
    onImageLoaded(event) {
        console.log("onimageloaded");
        const sizes = [30, 40, 50, 60, 80];
        this.bigImageUploadedHieght = event.target.naturalHeight;
        this.bigImageUploadedWidth = event.target.naturalWidth;
        localStorage.setItem('bigImgHeigth', this.bigImageUploadedHieght);
        localStorage.setItem('bigImgWidth', this.bigImageUploadedWidth);
        sizes.forEach((size) => {
            this.bigImgWidthMap[size] = this.bigImageUploadedWidth;
            this.bigImgHeightMap[size] = this.bigImageUploadedHieght;
            this.ratio = this.getRatio(
                this.bigImgWidthMap[size],
                this.bigImgHeightMap[size]
            );
            if (this.bigImgWidthMap[size] >= this.bigImgHeightMap[size]) {
                if (this.bigImgWidthMap[size] > 1000) {
                    this.bigImgWidthMap[size] = 1000;
                    this.bigImgHeightMap[size] = this.bigImgWidthMap[size] / this.ratio;
                }
                this.smallImgWidthMap[size] = Math.floor(
                    this.bigImgWidthMap[size] / size
                );
                const tempSize = this.bigImgWidthMap[size] / size;
                this.smallImgHeightMap[size] = this.smallImgWidthMap[size];
                this.bigImgWidthMap[size] = size * this.smallImgWidthMap[size];
                this.bigImgHeightMap[size] =
                    this.smallImgWidthMap[size] *
                    Math.round(this.bigImgHeightMap[size] / tempSize);
            } else {
                if (this.bigImgHeightMap[size] > 1000) {
                    this.bigImgHeightMap[size] = 1000;
                    this.bigImgWidthMap[size] = this.bigImgHeightMap[size] / this.ratio;
                }
                this.smallImgHeightMap[size] = Math.floor(
                    this.bigImgHeightMap[size] / size
                );
                const tempSize = this.bigImgHeightMap[size] / size;
                this.smallImgWidthMap[size] = this.smallImgHeightMap[size];
                this.bigImgHeightMap[size] = size * this.smallImgHeightMap[size];
                this.bigImgWidthMap[size] =
                    this.smallImgHeightMap[size] *
                    Math.round(this.bigImgWidthMap[size] / tempSize);
            }
            this.previewTextImg[size] = `${+(
                this.bigImgWidthMap[size] / this.smallImgWidthMap[size]
            )} x ${+(this.bigImgHeightMap[size] / this.smallImgHeightMap[size])}`;
        });
    }
    onFileDropped(event) {
        this.droppedFiles = [...event.addedFiles];
        this.onFileSelected({
            target: {
                files: [event.addedFiles[0]]
            }
        })
    }
    getTheFile() {
        window.scrollTo(0, 0);
        this.changeDetectorRef.detectChanges()

        const el = this.context2.canvas;
        const that = this;
        el.toBlob(function (blob) {
            var link = document.createElement('a');
            link.setAttribute('href', URL.createObjectURL(blob));
            link.setAttribute('download', 'mozlike.jpeg');

            if (document.createEvent) {
                var evtObj = document.createEvent('MouseEvents');
                evtObj.initEvent('click', true, true);
                link.dispatchEvent(evtObj);
            } else if (link.click) {
                link.click();
            }
            that.changeDetectorRef.detectChanges()
            const element = document.getElementById('panzoomCanvas');
            that.panz = panzoom(element, {
                bounds: false,
                maxZoom: 10,
                minZoom: 0.25
            })
            that.changeDetectorRef.detectChanges()
        }, 'image/jpeg');
    }
    async ngOnInit(): Promise<void> {
        document.getElementsByTagName('nav')[0].style.backgroundColor = '#3f51b5';
    }

    isSmallScreen() {
        if (window.innerWidth < 800) {
            return true;
        }
        return false;
    }
    blobToFile = (theBlob: Blob, fileName: string): File => {
        var b: any = { ...theBlob, lastModifiedDate: new Date(), name: fileName };
        return <File>theBlob;
    };

    onJsonChange(event) {
        const fileReader = new FileReader();
        fileReader.readAsText(event.target.files[0], "UTF-8");
        fileReader.onload = (ev) => {
            const json = JSON.parse(ev.target.result.toString());
            const base = json.url;
            const urls = json.urls;
            const w = parseInt(base.split("?")[1].split("h=")[0].replace("w=", "").replace("&", ""));
            const h = parseInt(base.split("?")[1].split("h=")[1]);
        }
        fileReader.onerror = (error) => {
            console.log(error);
        }
    }
    async onFileSelected(event) {
        this.allowBigUpload = false;
        this.bigImgStatus = 'uploadTheBig';
        this.bigImgSelected = undefined;
        const blob = await this.resizeBigImage(
            event.target.files[0],
            800,
            800
        );
        const file = this.blobToFile(blob, "t");
        this.bigImgSelected = file;
        if (this.bigImgSelected) {
            const reader = new FileReader();

            reader.readAsDataURL(this.bigImgSelected);

            reader.onload = (e) => {
                this.bigImgSelectedUrl = e.target.result;
                this.onUpload();
            };
        }
    }
    resizeBigImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = URL.createObjectURL(file);
            image.onload = () => {
                const width = image.width;
                const height = image.height;

                if (width <= maxWidth && height <= maxHeight) {
                    resolve(file);
                }

                let newWidth;
                let newHeight;
                let maxWidthTemp = maxWidth;
                let maxHeightTemp = maxHeight;
                if (width < maxWidth) {
                    maxWidthTemp = width;
                }
                if (height < maxHeight) {
                    maxHeightTemp = height;
                }
                if (width > height) {
                    newHeight = height * (maxWidthTemp / width);
                    newWidth = maxWidthTemp;
                } else {
                    newWidth = width * (maxHeightTemp / height);
                    newHeight = maxHeightTemp;
                }

                const canvas = document.createElement('canvas');
                canvas.width = newWidth;
                canvas.height = newHeight;
                this.bigImageUploadedWidth = parseInt(newWidth);
                this.bigImageUploadedHieght = parseInt(newHeight);
                this.changeDetectorRef.detectChanges();
                const context = canvas.getContext('2d');

                context.drawImage(image, 0, 0, newWidth, newHeight);
                canvas.toBlob(resolve, file.type);
            };
            image.onerror = reject;
        });
    }
    onUpload() {
        this.allowBigUpload = true;
        this.finalBigImgs = [];
        this.uploadService.uploadBigImage(this.bigImgSelected).subscribe((e) => {
            if (e.type === HttpEventType.UploadProgress) {
                this.bigImgLoadingProgress = (e.loaded / e.total) * 100 + '%';
                this.bigImgStatus = 'loading';
            } else if (e.type === HttpEventType.Response) {
                this.bigImgUploadedUrl = e.body.urls[0];
                this.changeDetectorRef.detectChanges();
                this.bigImgStatus = 'bigImageUploaded';
                localStorage.setItem('bigImgUploadedUrl', this.bigImgUploadedUrl);
            }
        });
    }
}
