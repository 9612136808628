<div class="container" *ngFor="let photo of photos; let i = index">
  <mat-card class="example-card">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title>{{ photo.title }}</mat-card-title>
      <mat-card-subtitle>*Not for sale</mat-card-subtitle>
    </mat-card-header>

    <div class="openseadragon" mat-card-image id="openseadragon{{i}}"></div>
    <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" alt="Photo of a Shiba Inu"> -->
    <mat-card-content>
      <p>
      </p>
    </mat-card-content>
    <mat-card-actions>
    </mat-card-actions>
  </mat-card>
</div>
