<div [ngStyle]="{'background-image': getBackgroundImg()}" class="view jarallax">
  <!-- Mask & flexbox options-->
  <div class="mask rgba-black-light d-flex justify-content-center align-items-center">
    <!-- Content -->
    <div class="container">
      <!--Grid row-->
      <div class="row">
        <!--Grid column-->
        <div class="col-md-12 mb-4 white-text text-center">
          <h1 class="h1-reponsive white-text text-uppercase font-weight-bold mb-0 pt-md-5 pt-5 wow fadeInDown"
            data-wow-delay="0.3s"><img src="../../assets/img/logo2.png" style="
            width: 52px;
            margin-bottom: 0.23rem;
            display: inline-block;
        "><strong style="display: inline-block;">OZLIKE</strong></h1>
          <br class="hr-light my-4 wow fadeInDown" data-wow-delay="0.4s">
          <h5 class="text-uppercase mb-4 white-text wow fadeInDown" data-wow-delay="0.4s">
            <strong>{{'artFactory' | translate}}</strong>
          </h5>
          <!-- <a type="button" routerLink='/upload' mdbWavesEffect class="btn btn-primary btn-lg"
            data-wow-delay="0.4s">{{'createYourOwn' | translate}} ->
          </a>
          <br class="hr-light my-4 wow fadeInDown" data-wow-delay="0.4s">
          <br class="hr-light my-4 wow fadeInDown" data-wow-delay="0.4s"> -->
          <!-- <a type="button" routerLink='/gallery' mdbWavesEffect class="btn btn-success btn-lg"
            data-wow-delay="0.4s">{{'gallery' | translate}} ->
          </a> -->
          <!-- <br class="hr-light my-4 wow fadeInDown" data-wow-delay="0.4s">
          <br class="hr-light my-4 wow fadeInDown" data-wow-delay="0.4s">
          <a type="button" (click)='openCreateMosaicModal()' mdbWavesEffect class="btn btn-primary btn-lg"
            data-wow-delay="0.4s">{{'moreInfo' | translate}}
          </a> -->
          <!-- <h5 class="text-uppercase mb-4 white-text wow fadeInDown" data-wow-delay="0.4s"><strong>Check out example
              below</strong>
          </h5> -->
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
    <!-- Content -->
  </div>
  <!-- Mask & flexbox options-->
</div>
<!-- <div class="video" style="width: 100%; padding-top: 20px;">
  <iframe height="100%" width="100%" style="background-color: #0d47a1 !important;"
    src="https://view.mozlike.com/?url=https://static.sajtzadan.com/janetijana.webp"></iframe>
</div> -->
<!-- <hr> -->
<!-- <div class="video" style="width: 100%;"> -->
<!-- <youtube-player class="iframe" videoId="ICvva8gI1ds" suggestedQuality="highres" [height]="250" [style.width.vw]="100">
  </youtube-player> -->
<!-- <iframe id="existing-iframe-example" class="embed-responsive-item"
    src="//www.youtube.com/embed/s-HYm8J6CM8?start=0&amp;showinfo=0&amp;allow=autoplay&amp;controls=0&amp;enablejsapi=1&amp;html5=1"
    frameborder="0" allowfullscreen=""></iframe> -->
<!-- </div> -->
<section id="scroll">
  <div class="container px-sm-0">
    <div class="row gx-5 align-items-center">
      <div class="col-lg-6 order-lg-2">
        <div class="p-3"><img class="img-fluid" src="assets/img/sl3.jpg" alt="..." /></div>
      </div>
      <div class="col-lg-6 order-lg-1 text-center">
        <div class="p-1">
          <h2 class="display-4"> {{ 'uploadBigImage' | translate }}</h2>
          <p> {{ 'bigImageInfo' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Content section 2-->
<section>
  <div class="container px-sm-0">
    <div class="row gx-5 align-items-center">
      <div class="col-lg-6">
        <div class="p-3"><img class="img-fluid " src="assets/img/sl2.png" alt="..." /></div>
      </div>
      <div class="col-lg-6 text-center">
        <div class="p-1">
          <h2 class="display-4">{{ 'uploadSmallImages' | translate }}</h2>
          <p> {{ 'smallImagesInfo' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Content section 3-->
<section>
  <div class="container px-sm-0">
    <div class="row gx-5 align-items-center">
      <div class="col-lg-6 order-lg-2">
        <div class="p-3"><img class="img-fluid" src="assets/img/sl1.jpg" alt="..." /></div>
      </div>
      <div class="col-lg-6 order-lg-1 text-center">
        <div class="p-1">
          <h2 class="display-4">{{ 'beCreative' | translate }}</h2>
          <p> {{ 'beCreativeInfo' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container px-sm-0">
    <div class="row gx-5 align-items-center">
      <div class="col-lg-6">
        <div class="p-3"><img class="img-fluid " src="assets/img/sl4.jpg" alt="..." /></div>
      </div>
      <div class="col-lg-6 text-center">
        <div class="p-1">
          <h2 class="display-4">{{ 'print' | translate }}</h2>
          <p> {{ 'printOnDemand' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container px-5 px-sm-0">
    <div class="row gx-5 align-items-center">
      <div class="col-lg-6 order-lg-2 text-center">
        <div class="p-3"><img class="img-fluid" src="assets/img/sl5.jpg" alt="..." /></div>
      </div>
      <div class="col-lg-6 order-lg-1">
        <div class="p-1 text-center">
          <a type="button" routerLink='/upload' mdbWavesEffect class="btn btn-primary btn-lg"
            data-wow-delay="0.4s">{{'createYourOwn' | translate}} </a>
          <br class="hr-light my-4 wow fadeInDown" data-wow-delay="0.4s">
          <br class="hr-light my-4 wow fadeInDown" data-wow-delay="0.4s">
          <a type="button" routerLink='/gallery' mdbWavesEffect class="btn btn-success btn-lg"
            data-wow-delay="0.4s">{{'gallery' | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<br>
<br>