<div class="content" style="padding-top: 0px; text-align: center;">
    <div *ngIf="!paypalScreen">
        <mdb-card-text>
            <mdb-card-title style="font-weight: bold;">{{firestoreUser.email}}</mdb-card-title>
        </mdb-card-text>
        <mdb-card-text>{{ 'tokensLeft' | translate }}: <mdb-card-title style="font-weight: bold;">
                {{firestoreUser.tokens}}
            </mdb-card-title>
        </mdb-card-text>
        <br>
        <button (click)="payment(1, 20)" class="mr-auto btn btn-primary btn-rounded btn-md">1
            {{ 'tokensSingle' | translate }} $20</button>
        <br>
        <br>
        <button (click)="payment(5, 80)" class="mr-auto btn btn-primary btn-rounded btn-md">5
            {{ 'tokensPlural' | translate }} $80</button>
        <br>
        <br>
        <button (click)="payment(10, 159)" class="mr-auto btn btn-success btn-rounded btn-md">10
            {{ 'tokensPlural' | translate }}
            $159</button>
        <br>
        <br>
        <button (click)="payment(20, 319)" class="mr-auto btn btn-primary btn-rounded btn-md">20
            {{ 'tokensPlural' | translate }}
            $319</button>
        <br>
        <br>
        <button (click)="payment(50, 799)" class="mr-auto btn btn-primary btn-rounded btn-md">50
            {{ 'tokensPlural' | translate }}
            $799</button>
        <br>
        <br>
        <button class="mr-auto btn btn-success btn-rounded btn-md" disabled>100+ {{ 'tokensPlural' | translate }} =
            {{ 'onEnquiry' | translate }}</button>
        <br>
        <br>
        <mat-divider></mat-divider>
        <mat-label style="color: #033177; font-weight: bolder;">
            {{ 'tokensNote' | translate }}
        </mat-label>
        <mat-divider></mat-divider>
        <br>
        <br>
        <button class="mr-auto btn btn-warning btn-lg"
            (click)="router.navigate(['upload'])">{{ 'create' | translate }}</button>
    </div>
    <mdb-card-title *ngIf="paypalScreen" style="font-weight: bold;">{{orderInfo}}</mdb-card-title>
    <div *ngIf="paypalScreen" class="paypal-test">
    </div>
    <button *ngIf="paypalScreen" (click)="back()" class="mr-auto btn btn-primary btn-rounded btn-sm">Back</button>

</div>