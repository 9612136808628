<div class="displayTable" style="padding-top: 68px;">
    <div class="displayTableCell">
        <div class="authBlock">
            <h3>{{ 'thanksForReg' | translate }}</h3>
            <div class="formGroup" *ngIf="authService.userData as user">
                <p class="text-center">{{ 'weHaveSentEmail' | translate }} <strong>{{user.email}}</strong>.</p>
                <p class="text-center">{{ 'pleaseCheckEmail' | translate }}</p>
            </div>

            <!-- Calling SendVerificationMail() method using authService Api -->
            <div class="formGroup">
                <button type="button" class="btn btnPrimary" (click)="authService.SendVerificationMail()">
                    <i class="fas fa-redo-alt"></i>
                    {{ 'resendEmail' | translate }}
                </button>
            </div>
            <hr>
            <div class="formGroup">
                <button type="button" class="btn btnPrimary" (click)="refresh()">
                    <i class="fas fa-redo-alt"></i>
                    Refresh
                </button>
            </div>
        </div>
        <div class="redirectToLogin">
            <span>{{ 'goBackTo' | translate }}<span class="redirect" routerLink="/sign-in">
                    {{ 'signIn' | translate }}</span></span>
        </div>
    </div>
</div>