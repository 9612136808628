import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../views/home/home.component';
import { UploadComponent } from '../upload/upload.component';
import { GalleryComponent } from '../gallery/gallery.component';
import { ConfirmationComponent } from '../views/confirmation/confirmation.component';
import { PrivacyComponent } from '../privacy/privacy.component';
import { AuthGuard } from '../guards/auth.guard';
import { SignInComponent } from '../sign-in/sign-in.component';
import { SecureInnerPagesGuard } from '../guards/secure-inner-pages.guard';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { VerifyEmailComponent } from '../verify-email/verify-email.component';
import { SecuredGuard } from '../guards/secured.guard';
import { TogetherComponent } from '../together/together.component';
import { ShareComponent } from '../together-push/share.component';
import { ProfileComponent } from '../profile/profile.component';
import { AnimateComponent } from '../animate/animate.component';
import { PricingComponent } from '../pricing/pricing.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [SecuredGuard] },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'upload',
    component: UploadComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    canActivate: [SecureInnerPagesGuard]
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    canActivate: [SecureInnerPagesGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [SecuredGuard]
  },
  {
    path: 'verify-email-address',
    component: VerifyEmailComponent,
    canActivate: [SecuredGuard]
  },
  {
    path: 'gallery',
    component: GalleryComponent,
    canActivate: [SecuredGuard]
  },
  {
    path: 'pricing',
    component: PricingComponent,
    canActivate: [SecuredGuard]
  },
  {
    path: 'confirmation',
    component: ConfirmationComponent,
    canActivate: [SecureInnerPagesGuard]
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    canActivate: [SecuredGuard]
  },
  {
    path: 'together',
    component: TogetherComponent,
    canActivate: [SecuredGuard]
  },
  {
    path: 'share',
    component: ShareComponent,
    canActivate: [SecuredGuard]
  },
  {
    path: 'animate',
    component: AnimateComponent,
    canActivate: [SecuredGuard]
  },
];

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class RoutingModule { }
