<div class="content" style="text-align: center;">

    <div fxLayout="row wrap" fxLayoutGap="16px grid">
        <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">

            <mat-card class="" style="text-align: center;">
                <h3>{{ 'mosaic' | translate }} {{ 'tokens' | translate }}:</h3>
                1 {{ 'tokensSingle' | translate }} = $20
                <br>
                5 {{ 'tokensPlural' | translate }} = $80
                <br>
                10 {{ 'tokensPlural' | translate }} = $159
                <br>
                20 {{ 'tokensPlural' | translate }} = $319
                <br>
                50 {{ 'tokensPlural' | translate }} = $799
                <br>
                100 + {{ 'tokensPlural' | translate }} = {{ 'onEnquiry' | translate }}
                <br>
            </mat-card>

            <mat-card class="" style="text-align: center;">
                <h3>{{ 'contact' | translate }}:</h3>
                <mdb-icon fas icon="envelope" size="1x" class="mr-0"></mdb-icon>marko.jereminov@mozlike.com
            </mat-card>
            <mat-label style="color: #033177; font-weight: bolder;">
                {{ 'tokensNote' | translate }}
            </mat-label>
            <mat-divider></mat-divider>
            <br>
            <br>
            <button class="mr-auto btn btn-warning btn-lg"
                (click)="router.navigate(['profile'])">{{ 'buy' | translate }}</button>
        </div>
    </div>
</div>