<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{'create' | translate}}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <p>
    {{'createInfo' | translate}}
  </p>
  <p>
    {{'printInfo' | translate}}
  </p>
  <p>
    Instagram: <a class="ins-ic" href="https://www.instagram.com/mozlike.official/">
      <mdb-icon fab icon="instagram" size="2x" class="mr-md-5 mr-3"></mdb-icon>
    </a>
  </p>
  <p>
    Email: <span style="font-weight: bold;">office@mozlike.com</span>
  </p>
</div>